import React from 'react';
import styled from './Heading.module.scss';

interface Props {
  heading: string;
  textAling?: 'end' | 'center' | 'start';
  color?: 'white' | 'blue';
  mode: 'h2' | "h3";
  textTransform?: 'uppercase';
}

function Heading({ 
  heading, 
  textAling='start', 
  color='blue', 
  mode, 
  textTransform
}: Props) {

  const style: React.CSSProperties = {
    textAlign: textAling,
    textTransform: textTransform,
  }

  const className: string = `${ styled.text } ${ styled[color] }`;
  
  return (
    <>
      { mode === "h3" && (
        <h3 className={ className } style={ style } >
          { heading }
        </h3>
      )}

      { mode === "h2" && (
        <h2 className={ className } style={ style } >
          { heading }
        </h2>
      )}
    </>
  )
}

export default Heading;
