import React from 'react';
import styled from './LinkList.module.scss';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

export interface ItemProps {
  to: string; // path to file or route
  file?: boolean; // is file mode checking
  fontClass: string; // instead .svg or .png use font which add ::before tag
  name: string; // name of link
}

function Item({ to, file, fontClass, name,}: ItemProps) {
  // compleated styles
  const style: string = `${ fontClass } ${ styled.link }`;

  return (
    <>
      {/* File mode */}
      {file && (
        <a 
          href={ to } 
          className={ style } 
          target="_blank" 
          rel="noreferrer"
        >
          { name }
        </a>
      )}

      {/* Route mode */}
      {!file && (
        <Link 
          to={ to } 
          className={ style }
        >
          { name }
        </Link>
      )}
    </>
  )
}

export interface LinkListProps {
  data: ItemProps[]
}

function LinkList({ data }: LinkListProps) {
  const { t } = useTranslation();
  
  return (
    <div className={ styled.list }>
      { data.map((item: ItemProps, index: number)=> (
        <Item 
          key={ index }
          to={ item.to } 
          file={ item.file } 
          fontClass={ item.fontClass } 
          name={ t(item.name) }
        />
      )) }
    </div>
  )
}

export default LinkList;
