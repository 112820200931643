import React from 'react';
import styled from './index.module.scss';
import CustomContainer from '../customContainer/CustomContainer';
import Heading from '../heading/Heading';
import { AbouUsData } from '../../data/abouUsData';
import { useTranslation } from 'react-i18next';

interface Props {
  data: AbouUsData;
}

interface AvardsProps {
  text: string;
  img?: string;
  number?: string;
  percent?: true;
}

function Avards({ text, img, number, percent}: AvardsProps) {
  const { t } = useTranslation();

  return (
    <div className={ styled.avards_container }>
      {img && (<img className={ styled.img } src={ img } alt={ text } />)}
      {number && (
        <div>
          <span 
            className={ styled.big_title }
          >
            { number }
          </span>
          { percent &&(
            <span className={ styled.small_title }>
              %
            </span>
          )}
        </div>
      )}
      <p className={ styled.paragraph }> { t(text) } </p>
    </div>
  )
}

function AboutUs({ data }: Props) {
  const { t } = useTranslation();
  return (
    <div id={ data.id }>
      <CustomContainer>
        <Heading 
          heading={ t(data.title) } 
          mode="h3" 
          textAling="center"
        />
        <p className={styled.paragraph}> { t(data.text) } </p>
      
      <h4 className={ styled.h4 }> { t(data.listTitle) } </h4>
      <div className={ styled.list_container}>
        { data.list.map((el: AvardsProps, i: number) => (
          <Avards 
            key={i}
            text={ el.text }
            percent={ el.percent }
            img={ el.img }
            number={ el.number }
          />
        ))}
      </div>
      </CustomContainer>
    </div>
  )
}

export default AboutUs;
