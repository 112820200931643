import React from 'react';
import FirstBlock from '../../components/FirstBlock';
import firstServicesData from '../../data/firstServicesData';
import servicesService1 from '../../data/servicesService1';
import ServicesSection from '../../components/servicesSection/ServicesSection';
import servicesService2 from '../../data/servicesService2';
import servicesService3 from '../../data/servicesService3';

export default function Services() {
  return (
    <div className='background-pages'>
      <FirstBlock data={ firstServicesData }/>
      <ServicesSection data={ servicesService1 } />
      <ServicesSection data={ servicesService2 } />
      <ServicesSection data={ servicesService3 } />
    </div>
  )
}
