
export interface QaData {
  title: string;
  buttonText: string;
  list: {question: string; answer: string}[]
}

const qaData: QaData = {
  title: 'qa-for-debtor-title',
  buttonText: 'qa-for-debtor-button-text',
  list: [
    {
      question: 'qa-for-debtor-1-question',
      answer: 'qa-for-debtor-1-answer',
    },
    {
      question: 'qa-for-debtor-2-question',
      answer: 'qa-for-debtor-2-answer',
    },
    {
      question: 'qa-for-debtor-3-question',
      answer: 'qa-for-debtor-3-answer',
    },
    {
      question: 'qa-for-debtor-4-question',
      answer: 'qa-for-debtor-4-answer',
    },
    {
      question: 'qa-for-debtor-5-question',
      answer: 'qa-for-debtor-5-answer',
    }
  ]
}

export default qaData;
