import React from 'react'
import { useTranslation } from 'react-i18next';

interface Props {
  titleColor?: string;
  descriptionColor?: string;
  positionText: 'start' | 'end' | 'center';
  descriptionList?: string[];
  title: string;
}

function H2Component({ 
  title, 
  descriptionList, 
  positionText,
  titleColor='text-white',
  descriptionColor='text-white',
}: Props) {
  const { t } = useTranslation();
  const textPosition: string = `text-${positionText}`
  const titleClass: string = titleColor 
    + " fs-1 pt-5 pb-3 " 
    + textPosition;
  const descriptionClass: string = descriptionColor 
    + " " 
    + textPosition;

  return (
    <>
      <h2 className={ titleClass }>
        { t(title) }
      </h2>
      { descriptionList!.map((description: string) => (
        <p className={ descriptionClass }>
          { t(description) }
        </p>
      ))}
    </>
  )
}

export default H2Component;
