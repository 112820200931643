import React from 'react';
import { Link } from 'react-router-dom';
import styled from './index.module.scss';

interface LogoProps{
  to: string;
  src: string;
  alt: string;
  className: string;
}

const Logo = ({ src, alt, to, className }: LogoProps) => {
  return (
    <div className={className}>
      <Link to={to}>
        <img 
          src={src} 
          alt={alt}
          className={styled.logo}
        />
      </Link>
    </div>
  )
}

export default Logo;