import { PostsData } from "../components/posts/Posts";
import sequre from "../assets/news/Informatsijna-gigiiena-pid-chas-vijny-7-bazovyh-pravyl.jpg";
import chess from "../assets/news/chess.jpg";
import matches from "../assets/news/matches_image.jpg";
import doImage from "../assets/news/DoImage.jpg";

const publicationsData: PostsData = {
  id: 'section-2',
  title: 'Публікації',
  type: 'publications',
  postList: [
    {
      date: '14.12.23',
      title: '🌟 Вторинні вигоди невдалих бізнес-рішень:',
      paragraphList: [
        'Підприємці з досвідом розуміють, що навіть невдалий бізнес-проект може приховувати важливі вторинні вигоди:',

        '✅ Розвиток стратегічного мислення: Невдалий проект може вимагати перегляду стратегії і розвитку нових навичок, які в подальшому будуть корисними.',

        '✅ Стійкість у складних умовах: Подолання негативних результатів може збільшити стійкість, що є цінною якістю для будь-якого підприємця.',

        '✅ Приховані можливості: Поразка може розкрити нові можливості і спонукати до пошуку альтернативних шляхів до успіху.',

        'Давайте подивимось на приклад корпорації "InnoTech Solutions," яка є глобальним лідером у сфері інноваційних технологій. Ця корпорація, також зіткнулася з невдалими проектами, з яких виникли неймовірні вторинні вигоди.',

        '✅ Розвиток стратегічного мислення: У 2015 році, "InnoTech Solutions" запустили амбіційний проект з розробки інноваційної продукції, яка, на жаль, не завершилася успіхом через технічні труднощі та ринкові коливання. Однак ця невдача змусила команду переглянути свої стратегії та залучити висококваліфікованих фахівців для розв\'язання технічних завдань. Цей досвід сприяв розвитку стратегічного мислення у всій компанії та підвищив її конкурентоспроможність на ринку.',

        '✅ Стійкість у складних умовах: У 2020 році, під час пандемії COVID-19, "InnoTech Solutions" зустрілися зі складнощами в управлінні ланцюгом постачання і зменшенням попиту на їх продукцію. Замість паніки, компанія використала цю ситуацію як можливість для оптимізації процесів і розробки нових продуктів, які відповідали новим умовам ринку.',

        '✅ Приховані можливості: Під час розгляду можливих стратегічних альтернатив, компанія "InnoTech Solutions" виявила, що індустрія великих даних та штучного інтелекту швидко розвивається. Вони вирішили зосередитися на цих сегментах ринку, і цей стратегічний поворот виявився вкрай успішним, дозволяючи компанії розширити свій вплив і здобути нові ринки.',

        '🚀 Цей кейс показує, що психологія вторинних вигод в бізнесі відкриває нові горизонти для розвитку та підвищує рівень свідомості. Якщо ви зустрілися з невдачами у вашому бізнесі, не сприймайте їх як кінець, але як можливість для вдосконалення. Активно вивчайте вторинні вигоди, будьте відкриті до нових ідей та завжди прагніть до досягнення нових вершин.',

        'Матеріал підготований для Вас фахівцями',

        '"Інтер-Ріск Україна"',
      ],
      imgs: [ doImage ],
    },
    {
      date: '27.11.23',
      title: '☝️☝️Захистіть свій колектив від втрати мотивації, через війну!',
      paragraphList: [
        'Життя в умовах війни може суттєво вплинути на нашу мотивацію та настрій. Страх, тривога та безпекові ризики можуть зменшити енергію та бажання продовжувати працювати в колективі. Однак, існують кілька кроків, які можуть допомогти зберегти мотивацію та підтримати одне одного в такі непрості часи.',

        '💡 Поділлюся кількома порадами, як зберегти мотивацію в колективі під час війни 💡',

        '✅ Створіть безпечне середовище: Зверніть увагу на психологічний комфорт вашого колективу. Забезпечте можливість відкритого спілкування, де кожен може висловити свої страхи та тривоги. ',

        'Розуміння та підтримка одне одного стануть важливим фактором у збереженні мотивації.',

        '✅ Зосередьтеся на спільних цілях: Війна може створювати відчуття безсилля та безперспективності. Важливо пам\'ятати, що ви всі є частиною колективу зі спільною метою. Зосередьте увагу на цілях, яких ви можете досягти разом.',

        '✅ Святкуйте успіхи: Не забувайте відзначати досягнення та успіхи як окремих осіб, так і всього колективу. Впроваджуйте систему визнання та нагород, щоб підкреслити важливість праці та зусиль кожного члена команди.',

        '✅ Підтримуйте психологічний стан: Важливо дбати про своє психологічне благополуччя. Регулярно відпочивайте, займайтеся фізичними вправами та релаксацією. Збереження позитивного настрою допоможе вам і вашому колективу подолати виклики, що виникають через війну.',

        '✅ Будьте обережними з інформацією, яку споживаєте. Уникайте поширення непідтверджених чуток та спекулятивних новин, оскільки вони можуть поглибити тривогу та втрату мотивації. Спирайтесь на достовірні джерела і пам\'ятайте, що добре обгрунтована інформація може допомогти вам раціонально реагувати на складні ситуації.',

        '🤝 Втрата мотивації може бути природною реакцією на стресові умови війни, але взаємна підтримка може допомогти подолати ці труднощі та знову знайти внутрішню мотивацію.',

        '🛡️ Важливо пам\'ятати, що ви не самотні: Війна може бути важкою, але ви можете подолати виклики, зберігаючи мотивацію та підтримуючи одне одного.',

        'Матеріал підготований для Вас фахівцями',

        '"Інтер-Ріск Україна"',
      ],
      imgs: [ matches ],
    },
    {
      date: '07.11.23',
      title: 'Як зберегти психіку, в умовах війни❓',
      paragraphList: [
        '☝️Війна є однією з найскладніших та найтривожніших ситуацій, які тільки можуть статися в житті людини. Однак, навіть у таких складних умовах, існує можливість розвивати стресостійкість і зміцнювати свій духовний стан.',

        'Ось деякі корисні поради, які можуть допомогти зберегти свою психічну стійкість під час війни:',

        '✅Розмовляйте з кимось. Розмова з друзями або родичами може допомогти зняти стрес та знайти підтримку у складні моменти. Якщо немає можливості поговорити з кимось особисто, спробуйте знайти співрозмовника онлайн.',

        '✅Використовуйте техніки релаксації. Йога, медитація, дихальні вправи або просто глибоке дихання можуть допомогти знизити рівень тривоги та підвищити рівень стресостійкості.',

        '✅Зберігайте ритм. Щоденна рутина допоможе вам зберегти контроль над ситуацією та зменшити ризик перенавантаження. Піклуйтеся про своє здоров\'я. Фізичне здоров\'я пов\'язане з психічним, тому спробуйте дотримуватися здорового способу життя, щоб зміцнити свій організм.',

        '✅Приймайте факти такими, які вони є. Намагайтеся зосередитися на тому, що залежить від вас, а не на тому, що не можна змінити.',

        '✅У складних умовах важливо пам\'ятати, що ви не самі і що існує багато ресурсів та організацій, які можуть надати підтримку вам та вашій родині. Звертайтеся до них і рішуче просіть допомоги, якщо вона необхідна.',

        'Нарешті, важливо пам\'ятати, що війна - це тимчасове явище, хоча часом в це може бути важко повірити.',

        '🌅Пам\'ятайте, що найпотужніші зміни в житті, можна створити потужним наміром і професійний психолог може з цим допомогти.',

        'Матеріал підготований для Вас фахівцями',

        '"Інтер-Ріск Україна"',
      ],
      imgs: [ chess ],
    },
    {
      date: '07.10.23',
      title: '🔒 Захистіть себе від страшних наслідків війни за допомогою інформаційної гігієни! 🔒',
      paragraphList: [
        'Хочемо поділитися кількома важливими порадами, як поліпшити вашу інформаційну гігієну та уникнути потенційних негативних наслідків.',


        '💡 Приклад ситуацій, які ви можете впізнати 💡',


        '____ Уявіть, що ви перебуваєте в зоні бойових дій та публікуєте у соціальних мережах фотографії, на яких видно допомогу військових на передовій. Ви просто хотіли продемонструвати свою підтримку нашим захисникам. Але на жаль, така недбалість може призвести до небезпеки. Розвідка ворога використовує наші публікації для організації своїх атак. Ваше бажання показати свою активну волонтерську позицію може коштувати життів, усім кого ви хотіли підтримати.',


        '____ Припустимо, що ваш близький бездумно розповсюджує спекулятивні новини про війну. Ви намагаєтесь заспокоїти його, але він все більше панікує. Замість сперечання запропонуйте йому почитати про медіаграмотність та маніпуляції ЗМІ. Нехай він зрозуміє, що неперевірені сторінки та заголовки часто є пасткою.',


        '____ Можливо ви поспішаєте поширювати новини серед своїх знайомих, щоб ті найшвидше могли врятуватися. Однак, пам\'ятайте, що можуть з\'явитися факти, які спростовують ці новини. Важливо бути в курсі всіх оновлень і швидко реагувати на можливі спростування. Ви ж не хочете сприяти поширенню дезінформації, чи не так?',


        '🔐 Рекомендації для вас 🔐',


        '🚫 Подумайте, перш ніж ділитись: Відкладіть поширення сенсаційних новин на деякий час. Пам\'ятайте, що заголовки, які здобувають хайп, часто є фейковими або перебільшеними, а самі новини швидко спростовуються. Не ставте свою безпеку на карту через миттєве задоволення.',


        '🙅‍  Утримайтеся від політичних дискусій з незнайомцями у коментарях. Соціальні мережі повні тролів та ботів, і немає сенсу будувати з ними конструктивний діалог.',


        '💪 Розвивайте медіаграмотність: Ви – свідомий користувач, який заспокоює панікуючих близьких. Пропонуйте їм вчитися медіаграмотності та розумінню маніпуляцій, що поширюються через ЗМІ. Нехай вони усвідомлять, що кожна новина має лише часткове відношення до істини, тому потрібно робити багатосторонній аналіз ситуації.',


        '🔄 Будьте на крок попереду: Слідкуйте за оновленнями та можливими спростуваннями новин, особливо тих, якими ви вірите і які ви поширили. Будьте готові виправити свої помилки та робити краще, адже ваша точність – це безцінний інструмент.',


        '🛡️ Захистіть правду: Коли ви створюєте контент, уникайте непідтверджених фактів про війну. Навіть якщо ви хочете поділитися "сенсацією", пам\'ятайте, що ваші слова мають велику вагу. Будьте обережними та відповідальними – ви маєте силу вплинути на думки людей.',


        'Щоб вижити в часи війни, будьте мудрими та розсудливими, а інформація стане вашим сильним щитом у непевних часах. 🛡️💪',


        'Матеріал підготований для Вас фахівцями',

        '"Інтер-Ріск Україна"',
      ],
      imgs: [ sequre ],
    },
  ]
}

export default publicationsData;