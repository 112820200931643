import React from 'react';

interface Props {
  img: string;
  id: string;
}

export default function UrkaneMap({ img, id }: Props) {
  return (
    <div id={ id } >
      <img src={ img } alt="Urkaine map" style={{ width: "100%" }}/>
    </div>
  )
}
