import React, { useState } from 'react'
import { SelectServiceDataItem } from '../data/selectServiceData';
import { HashLink as Link } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../hooks/useMediaQuery';

function ServiceCircle({ 
  gif, 
  title,
}: { gif: string, title: string }) {
  const { t } = useTranslation();

  return (
    <>
      <img 
        src={ gif } 
        alt={ title } 
        style={{
          height: "195px",
          width: "195px",
        }}
        className="mx-auto mt-lg-5"
      />
      <h4 className="text-center text-decoration-none fs-5 py-4 px-2">{ t(title) }</h4>
    </>
  )
}

function ServiceListText({ 
  text, 
  img,
  title,
}: { 
  img: string,
  text: string,
  title: string,
}) {
  const { t } = useTranslation();

  return (
    <div className="custom-primary-background-color p-3">
      <img 
        src={ img } 
        alt={ title } 
        style={{
          height: "80px",
          width: "80px",
          backgroundColor: "white",
          borderRadius: "50%",
        }}
        className="mb-3"
      />
      <p className="text-white">
        { t(text) }
      </p>
    </div>
  )
}

function SelectServiceListItem({ 
  gif,
  img,
  title,
  to,
  text,
}: SelectServiceDataItem) {
  const [ isHover, setIsHover ] = useState<boolean>(false);
  const isMobile = useMediaQuery('(min-width: 960px)');

  const showText = ( bol: boolean) => {
    if (isMobile) {
      setIsHover(bol);
    } else {
      setIsHover(false);
    }
  }

  return (
    <Link
      onMouseEnter={() => showText(true)}
      onMouseLeave={() => showText(false)}
      to={ to } 
      className="text-decoration-none text-dark d-flex flex-column justify-content-center col"
      style={{
        minWidth: `${!isMobile ? "200px" : "300px"}`,
      }}
    >
      { isHover 
        ? ( <ServiceListText text={ text } img={ img } title={ title }/> )
        : ( <ServiceCircle gif={ gif } title={ title }/> )}

    </Link>
  )
}

export default SelectServiceListItem;
