import React from 'react';
import { QaData } from '../../data/qaData';
import { useTranslation } from 'react-i18next';
import SectionContainer from '../SectionContainer';
import Modal from '../modal/Modal';
import styled from './index.module.scss';

interface Props {
  id: string;
  data: QaData;
}

export default function Qa({ id, data }: Props) {
  const { t } = useTranslation();

  return (
    <SectionContainer
      id={id}
      title={data.title}
      mode='h3' 
      positionText='start'
    >
      <div className={ styled.container }>
        { data.list.map((
            el: {question: string, answer: string}, 
            i: number
          ) => (
            <div className={ styled.qustion_container } key={i}>
              <Modal 
                mode="qa" 
                list={ data.list } 
                start={i}
              >
                <h4 className={ styled.qustion_title }>
                  { t(el.question) }
                </h4>
              </Modal>
            </div>
        ))}
      </div>
    </SectionContainer>
  )
}
