import React from 'react';
import CustomContainer from '../customContainer/CustomContainer';

import styled from  './OurTeam.module.scss';
import Heading from '../heading/Heading';
import { OurTeamData, OurTeamItem } from '../../data/ourTeamData';
import { useTranslation } from 'react-i18next';

interface Props {
  data: OurTeamData;
  id: string;
}

export default function OurTeam({ data, id }: Props) {
  const { t } = useTranslation();
  
  return (
    <div id={ id }>
      <CustomContainer>
        <Heading heading={ t( data.title ) } mode="h3"/>

        <div className={ styled.container }>
          { data.list.map((el: OurTeamItem, index: number) => (
            <div key={ index }>
              <h4 className="h4Custom">
                { el.name }
              </h4>

              <h5 className="h5Custom">
                { el.position }
              </h5>
            </div>
          ))}
        </div>
      </CustomContainer>
    </div>
  )
}
