import background from '../assets/corousel1/pexels-photo-97130-min.jpg';
import { FirstBlockData } from '../components/FirstBlock';

const aboutData: FirstBlockData = {
  id: "section-0",
  background: background,
  data: {
    title: 'about-company-first-title',
    descriptionList: [
      'about-company-first-description-1',
      'about-company-first-description-2',
      'about-company-first-description-3',
    ],
    linkList: [
      {
        to: '#section-1',
        fontClass: '_icon-competition-cup',
        name: 'about-company-first-link-1',
      },
      {
        to: '#section-2',
        fontClass: '_icon-attach',
        name: 'about-company-first-link-2',
      },
      {
        to: '#section-3',
        fontClass: '_icon-targeting-target',
        name: 'about-company-first-link-3',
      },
      {
        to: '#section-4',
        fontClass: '_icon-handshake-deal',
        name: 'about-company-first-link-4',
      }
    ]
  }
}

export default aboutData;
