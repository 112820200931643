import company1 from '../assets/clientsLogos/company1.png';
import company2 from '../assets/clientsLogos/company2.png';
import company3 from '../assets/clientsLogos/company3.png';
import company4 from '../assets/clientsLogos/company4.png';
import company5 from '../assets/clientsLogos/company5.png';
import company6 from '../assets/clientsLogos/company6.png';
import company7 from '../assets/clientsLogos/company7.png';
import company8 from '../assets/clientsLogos/company8.png';
import company9 from '../assets/clientsLogos/company9.png';
import company10 from '../assets/clientsLogos/company10.png';
import company11 from '../assets/clientsLogos/company11.png';
import company12 from '../assets/clientsLogos/company12.png';
import company13 from '../assets/clientsLogos/company13.png';
import company14 from '../assets/clientsLogos/company14.png';
import company15 from '../assets/clientsLogos/company15.png';
import company16 from '../assets/clientsLogos/company16.png';
import company17 from '../assets/clientsLogos/company17.png';
import company18 from '../assets/clientsLogos/company18.png';
import company19 from '../assets/clientsLogos/company19.png';
import company20 from '../assets/clientsLogos/company20.png';


import pumb from '../assets/clientsLogos/pumb.svg';
import privat from '../assets/clientsLogos/Privat-Bank-01.svg';
import oschad from '../assets/clientsLogos/oschad.svg';
import fondGarantyvanya from '../assets/clientsLogos/fondGarantyvanya.png';
import img from '../assets/partners.jpg';
import vuso from '../assets/clientsLogos/vuso_company.svg'

export interface OursClientsData {
  title: string;
  background: string;
  clientsLogos: string[]
}

const oursClientsData: OursClientsData = {
  title: 'ours-clients-title',
  background: img,
  clientsLogos: [
    pumb,
    privat,
    oschad,
    fondGarantyvanya,
    vuso,
    company1,
    company2,
    company3,
    company4,
    company5,
    company6,
    company7,
    company8,
    company9,
    company10,
    company11,
    company12,
    company13,
    company14,
    company15,
    company16,
    company17,
    company18,
    company19,
    company20,
  ]
}

export default oursClientsData