import React from 'react';
import styled from './index.module.scss';

import { useTranslation } from 'react-i18next';

import CustomContainer from '../customContainer/CustomContainer';
import LinkButton from '../linkButton/LinkButton';


import socialsLogo from '../../assets/social/socials.png';
import { email, phone } from '../../data/contactsData';
import { facebookComapny, facebookEducation, googlemaps } from '../../data/socialNetworkLinksData';
import Modal from '../modal/Modal';
// import useMediaQuery from '../../hooks/useMediaQuery';

export default function Footer() {
  // const isTablet: boolean = useMediaQuery('(min-width: 960px)');
  const { t } = useTranslation();

  return (
    <footer 
      id='footer'
      className={ styled.container }
    >
      {/* <a href="#footer"> </a> */}
      <CustomContainer>
        {/* Showed on mobile mode */}

        <div className='d-flex flex-column pt-5 mb-4 d-md-none'>
          <h4 className='mb-4 d-inline-block text-center'>
            { `${ t( 'footer-have-any-questions' ) }?` }
          </h4>
            <Modal style={{ margin: '0 auto', }} mode="form">
              <LinkButton 
                to={'#'} 
                text={ t('footer-get-a-consultation') }
              />
            </Modal>
              
        </div>

        <div className='d-flex flex-column flex-md-row mb-4 pt-md-5 gap-3 gap-md-2'>
          <div className="col">
            <p className='text-center'>
              { `${ t( 'footer-call-us' ) }:` }
            </p>
            <a 
              href={"tel:" + phone}
              style={{
                textDecoration: 'none',
                fontSize: '18px',
                fontWeight: 500,
                color: '#fff',
                display: 'block',
                textAlign: 'center',
              }}
            >{ phone }</a>
          </div>

          <div className="col">
            <p className='text-center'>
              { `${ t( 'footer-write-to-us' ) }:` }
            </p>
            <a 
              href={"mailto:" + email}
              style={{
                textDecoration: 'none',
                fontSize: '18px',
                fontWeight: 500,
                color: '#fff',
                display: 'block',
                textAlign: 'center',
              }}
              >{ email }</a>
          </div>
          
          <div className="col">
            <p className='text-center'>
              { `${ t( 'footer-head-office-address' ) }:` }
            </p>
            <a 
              href={ googlemaps } 
              target="_blank" 
              rel="noreferrer"
              style={{
                textDecoration: 'none',
                fontSize: '18px',
                fontWeight: 500,
                color: '#fff',
                display: 'block',
                textAlign: 'center',
              }}
            >
              { `${ t( 'footer-office-address' ) }:` }
            </a>
          </div>

          <div className="col">
            <p className='text-center'> { `${ t( 'footer-follow-us' ) }:` } </p>
            <div className='d-flex justify-content-center flex-column'>
              <div
                style={{
                  display: "flex",
                  marginRight: "10px",
                  alignItems: "center",
                  marginBottom: "10px",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "inline-block",
                    marginRight: "10px",
                    marginBottom: "0"
                  }}
                > { `${ t( 'footer-facebook-company' ) }:`} </p>
                <a
                  href={ facebookComapny }
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    backgroundImage: `url(${ socialsLogo })`,
                    display: "inline-block",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '30px',
                    width: '30px',
                  }}
                > </a>
              </div>
              <div
                style={{
                  display: "flex",
                  marginRight: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p
                  style={{
                    display: "inline-block",
                    marginRight: "10px",
                    marginBottom: "0"
                  }}
                > { `${ t( 'footer-facebook-education' ) }:`}</p>
                <a
                  href={ facebookEducation }
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    backgroundImage: `url(${ socialsLogo })`,
                    display: "inline-block",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    height: '30px',
                    width: '30px',
                  }}
                > </a>
              </div>
            </div>
          </div>
        </div>

        {/* Showed on tablet mode */}

        <div className='d-none d-md-flex flex-column mb-4'>
          <h4 className='mb-4 d-inline-block text-center'>
            { `${ t('footer-have-any-questions') }?` }
          </h4>
          <Modal style={{ margin: '0 auto', }} mode="form">
            <LinkButton 
              to={'#'} 
              text={ t('footer-get-a-consultation') } 
            />
          </Modal>
        </div>

        {/* small text */}

        <div className="d-flex flex-column">
          <p
            style={{
              fontSize: '13px',
              color: '#fff',
              display: 'block',
              textAlign: 'center',
              marginBottom: '5px',
            }}
          >©1994 - { new Date().getFullYear() }. Інтер-Ріск Україна. Всі права захищені.</p>
          <a 
            href="https://www.linkedin.com/in/heorhii-pluhovyi-b54b81263/" 
            target='_blank'
            rel="noreferrer"
            style={{
              textDecoration: 'none',
              fontSize: '13px',
              color: '#fff',
              display: 'block',
              textAlign: 'center',
            }}
          >Created by Heorhii</a>
        </div>
      </CustomContainer>
    </footer>
  )
}
