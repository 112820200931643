import { PostsData } from "../components/posts/Posts";
import msppu from "../assets/news/MSPPU.jpg";
import meeting from "../assets/news/meeting1.jpg";
import notepad from "../assets/news/NZ210849.jpg";
import papers from "../assets/news/thumbnail-s-20230512155146-8139.jpg";
import fond from "../assets/news/630_360_1533811063-3938.jpg";
import ua_android from "../assets/news/ua_android.jpg";
import conference from "../assets/news/conference.jpg";

const newsData: PostsData = {
  id: 'section-1',
  title: 'Новини',
  type: 'news',
  postList: [
    {
      date: '18.12.23',
      title: 'УХВАЛЕННЯ ЗАКОНОПРОЄКТУ № 9363 ЦИФРОВІЗУЄ ВИКОНАВЧЕ ПРОВАДЖЕННЯ ТА ЗРОБИТЬ КОМФОРТНИМ СПЛАТУ БОРГУ ДЛЯ ДОБРОСОВІСНИХ БОРЖНИКІВ',
      paragraphList: [
        `Зміни Законодавства та Нововведення НБУ`,
        `2023 рік став періодом значних законодавчих ініціатив. Нові нормативні акти
        внесли зміни у сферу колекторської діяльності, зокрема, посилили вимоги до
        ліцензування та регулювання діяльності колекторських компаній. Національний
        банк України запровадив додаткові регуляторні механізми для захисту прав
        споживачів, а також встановив чіткі рамки для взаємодії колекторів з
        боржниками, спрямовані на попередження зловживань і неетичної поведінки.`,
        `- Встановлення жорстких рамок для взаємодії з боржниками, вимоги до
        прозорості діяльності колекторських компаній.`,
        `- Введення нових правил для ліцензування, забезпечення дотримання
        етичних норм.`,
        `- Підвищення стандартів захисту прав споживачів, обмеження агресивних
        методів стягнення боргів.`,
        `Вплив на Колекторські Компанії, їх Працівників та Клієнтів`,
        `Зміни в законодавстві суттєво вплинули на роботу колекторських
        компаній, зокрема на наші внутрішні процеси та взаємодію з клієнтами. Ми
        зіткнулися з необхідністю адаптації до нових правил та стандартів, що вимагало
        від нас підвищення кваліфікації та розвитку нових навичок. З погляду клієнтів,
        особливо банків та МФО, посилення регуляцій забезпечило більшу прозорість
        та захист їхніх інтересів, але й підвищило ризик штрафів, при співпраці з
        неперевіреними часом партнерами.`,
        `Технологічні Інновації у Колекторських Компаніях`,
       ` У 2023 році колекторські компанії інтенсивно включали передові технології у
        свої процеси:`,
        `- Використання AI для аналізу платоспроможності боржників,
        прогнозування ймовірності погашення боргів, автоматизації комунікацій.`,
        `- Розробка алгоритмів Машинного навчання, для оптимізації стратегій
        взаємодії з боржниками, з урахуванням індивідуальних особливостей та
        історії платежів.`,
        `Прогнози на 2024 Рік`,
        `- З огляду на світові економічні тенденції, може бути збільшення обсягу
        проблемної заборгованості, що може стимулювати зростання
        колекторського ринку.`,
        `- За деякими даними є тенденція до збільшення обсягу боргових портфелів
        на 17%, з прогнозом подальшого зростання на 20-25% у 2024 році.`,
        `- Розвиток технологій продовжить впливати на ефективність роботи
        колекторських компаній, підвищуючи нашу здатність адаптуватися до
        умов ринку.
        Висновок`,
        `2023 рік став важливим переломним моментом у розвитку колекторської
        діяльності в Україні. Зміни в законодавстві та регулюванні сприяли
        встановленню вищих стандартів роботи у цій сфері. Це відкриває нові
        перспективи для розвитку колекторського бізнесу, забезпечуючи більшу
        прозорість та ефективність у вирішенні боргових питань. Ми чітко бачимо
        збільшення ролі колекшену в економіці та впровадження нових технологій. І
        дуже важливо, щоб захист прав споживачів рухалися рівномірно із захистом
        прав кредиторів і колекторів`
      ],
      imgs: [ ua_android ],
    },
    {
      date: '18.12.23',
      title: 'Пост про Участь у Форумі Лідерів Колекшн',
      paragraphList: [
        'Привіт, друзі!',

        'Нещодавно наша команда мала чудову можливість взяти участь у Форумі Лідерів Колекшн, який відбувся 11 червня 2024 року в Києві. Цей захід став ключовою щорічною подією у сфері стягнення боргів, зібравши разом провідних експертів та лідерів ринку. Наша компанія була представлена у складі Генерального Директора, Директора ДДВ і Директора з Розвитку. Адже, наш керівний склад завжди змотивований бути на вістрі нових технологій і підходів ринку. Більше того, наша компанія зробила свій вклад, в дану конференцію, направивши своїм спікером Корпоративного Психолога.', 

        '🌟 Що Ми Дізналися:',

        'Інновації та Технології: Однією з головних тем форуму були інновації у технологіях стягнення боргів. Експерти обговорювали роль штучного інтелекту, аналізу даних та автоматизації у революції сфери колекшн.',

        'Юридичні та Етичні Аспекти: Ми взяли участь у сесіях, присвячених юридичним та етичним аспектам стягнення боргів. Це дало нам глибше розуміння нормативних вимог та найкращих практик у цій галузі.',

        'Ефективні Стратегії: Наша команда також вивчила стратегії ефективного спілкування з боржниками та адаптацію до змін у поведінці споживачів. Це знання допоможе нам оптимізувати нашу роботу та підвищити ефективність процесів стягнення.',

        '🤝 Нетворкінг та Обмін Досвідом:',

        'Форум також надав нам унікальну можливість для нетворкінгу. Ми зустрілися з колегами, обмінялися думками та кейсами, що безперечно збагатило наш професійний досвід.',

        '🚀 Враження:',

        'Цей захід був не лише навчальним, але й надзвичайно мотивуючим. Ми повернулися з новими ідеями, натхненням та готовністю впроваджувати нові підходи у нашій роботі.',

        'Дякуємо організаторам "ProMoney" за чудовий захід та всім спікерам за цінні знання!',
      ],
      imgs: [ conference ],
    },
    {
      date: '16.06.23',
      title: 'УХВАЛЕННЯ ЗАКОНОПРОЄКТУ № 9363 ЦИФРОВІЗУЄ ВИКОНАВЧЕ ПРОВАДЖЕННЯ ТА ЗРОБИТЬ КОМФОРТНИМ СПЛАТУ БОРГУ ДЛЯ ДОБРОСОВІСНИХ БОРЖНИКІВ',
      paragraphList: [
        'Міністерство юстиції при плануванні головної мети у сфері примусового виконання рішень ‒ повного та своєчасного їхнього виконання, розбиває шлях її досягнення на низку проміжних етапів, які успішно реалізуються та мають позитивні результати. Так вважає Директор Директорату правосуддя та кримінальної юстиції Мін’юсту Олександр Олійник.',
        'За словами заслуженого юриста України Міністерство юстиції уже і так зробило багато в напрямку цифровізації виконавчого провадження, але не все.',
        'Головними проблемами, з якими сьогодні стикаються добросовісні боржники, які якнайшвидше хочуть позбутися боргів, є:',
        '- тривале списання коштів з арештованих рахунків у зв’язку із частковим підключенням банків до інформаційної взаємодії щодо автоматизованого арешту коштів (так, з деякими банками обмін платіжними документами ще й досі відбувається в паперовій формі);',
        '- відсутність можливості автоматизованого зняття арештів з коштів (без втручання виконавця) і автоматичного виключення боржника з Єдиного реєстру боржників (далі ‒ ЄРБ).',
        'Як їх вирішити? Якою буде майбутня модель виконавчого провадження? Щоб отримати відповіді на ці питання читайте нову статтю Олександра Олійника Цифровізація виконавчого провадження - per aspera ad законопроєкту № 9363 | ЮРЛІГА (ligazakon.net).',
      ],
      imgs: [ notepad ],
    },
    {
      date: '07.06.23',
      title: 'Делегація від нашої компанії «Інтер-Ріск Україна», взяла участь у XIII Конференції «УПРАВЛІННЯ ПРОБЛЕМНОЮ ЗАБОРГОВАНІСТЮ», організаторами якої традиційно виступили «Business-Format». ',
      paragraphList: [
        'У конференції щорічно беруть участь: Керівники підрозділів по роботі з проблемною заборгованістю банків та фінансових компаній, Керівники служб безпеки, Керівники колекторських та юридичних компаній, кредитних бюро, Директори Юридичних Департаментів, Кредитних Департаментів, а також ті, кому цікаві питання конференції.',
        '“ЯК ВИ?” Це питання стало звичним для українців. Війна увірвалася у наші життя та змінила все. Все, що було раніше і як було раніше, стало іншим. І, безумовно, суттєві зміни відбулися у сфері коллекшн. Ми зустрічалися на Конференції у вересні 2022 року, щоб звірити годинник, дізнатися, що сталося за 6 місяців війни, зрозуміти як жити далі.',
        'Йде другий рік війни, але ми продовжуємо жити, працювати і вірити в якнайшвидшу нашу Перемогу. Тому нам усім дуже важливо традиційно зібратися, дізнатися, що відбувається у сфері колешні сьогодні на всіх рівнях (законодавчому, юридичному, практичному), зустрітися з колегами та обмінятися практичним досвідом роботи з боржниками.',
        'Для нас, було дуже цінним приділити увагу кожному блоку конференції, адже «Інтер-Ріск Україна» завжди йде в ногу з часом і передовими методами роботи. Особливо хотілось би відмітити найвищий рівень підготовки та модерації, в ранковій дискусії з учасниками колекторського ринку, де модератором виступав Пан Анджей Голка. Також, ми виділили для себе цінні інсайти, та підходи з блоків «Робота з проблемною заборгованістю та законодавство» і «Практика коллекшн у нових реаліях».',
        'З нетерпінням, очікуємо майбутніх конференцій, адже ділитися досвідом – це один з ключових факторів успіху українського колекшен!',
      ],
      imgs: [ meeting ],
    },
    {
      date: '29.05.23',
      title: 'Представники компанії «Інтер-Ріск Україна» взяли активну участь у Загальних зборах членів МСППУ.  Вважаємо, що загальні збори були дуже інформативними та результативними. ',
      paragraphList: [
        'МТППУ являє собою платформу для українсько-польських підприємств, для польських громадян, які працюють менеджерами в компаніях із польським капіталом і в міжнародних корпораціях України.',
        'Пан Шимон Ващин – отримав пропозицію очолити Асоціацію і ця пропозиція Правління була одностайно підтримана членами товариства.',
        'Щиро вітаємо, Пана Шимона! Віримо в нашу спільну перемогу і рішуче підтримуємо обраний курс на відновлення України, після закінчення війни!',
        'МTППУ вже понад 20 років – це міст між двома країнами-сусідами, яких об’єднує спільна спадщина та спільне майбутнє. Міцність такого зв’язку особливо актуальна сьогодні,  в часи, коли прокидається історична свідомість народів. Ми переконані, що наша спільна діяльність допомагає зростанню стабільності і економічної привабливості наших Членів та має безпосередній вплив на події сучасності і відносини між нашими країнами.',
        'Товариство утворює осередок взаємної підтримки, а офіс Товариства є найбільш відвідуваним польським осередком в Києві, де кожен може отримати необхідну інформацію, а у разі потреби – необхідну допомогу.',
        'Особливістю MSPPU є взаємна дружба та самовідданість, ми діємо в одному напрямку, а нинішня ситуація лише підкреслює цінності «мистецтва бути разом», де ми, як патріоти України, та Польші ведемо свій бізнес у дух взаємоповаги.',
      ],
      imgs: [ msppu ],
    },
    {
      date: '12.05.23',
      title: 'ЗМІНИ ЩОДО ОРГАНІЗАЦІЇ ПРИМУСОВОГО ВИКОНАННЯ РІШЕНЬ ПІД ЧАС ДІЇ ВОЄННОГО СТАНУ',
      paragraphList: [
        '11.04.2023 Верховною Радою України прийнято Закон України № 3048-ІХ «Про внесення змін до деяких законів України щодо окремих особливостей організації примусового виконання судових рішень і рішень інших органів під час дії воєнного стану» (далі – Закон № 3048-ІХ), який набрав чинності 06.05.2023.',
        'Вказаним Законом, зокрема, викладено у новій редакції пункт 10-2 розділу XIII «Прикінцеві та перехідні положення» Закону України «Про виконавче провадження».',
        'Цією редакцією змінено порядок розпорядження фізичними особами-боржниками коштами, на які накладено арешт органами державної виконавчої служби чи приватними виконавцями, у період дії в Україні воєнного стану, введеного Указом Президента України від 24.02.2022 № 64/2022.',
        'Так, у зазначений період фізичні особи-боржники, на кошти яких накладено арешт виконавцями, можуть здійснювати видаткові операції з поточного рахунку на суму в розмірі, що протягом одного календарного місяця не перевищує 2-х розмірів мінімальної заробітної плати, встановленої законом про Державний бюджет України на 1 січня поточного календарного року (13 400 грн.), а також сплачувати податки, збори без урахування такого арешту, за умови, що такий поточний рахунок визначений для здійснення видаткових операцій у порядку, встановленому цим підпунктом.',
        'Звернення стягнення у межах зазначеної суми на такому рахунку не здійснюється.',
        'У разі якщо арешт накладено на кошти на декількох поточних рахунках фізичної особи-боржника в одному банку або на поточних рахунках у різних банках, а також у разі наявності декількох виконавчих проваджень, для здійснення видаткових операцій має бути визначений лише один поточний рахунок фізичної особи-боржника в одному банку. Кількість виконавчих проваджень не впливає на розмір суми, яка не підлягає зверненню стягнення і на яку фізична особа-боржник може здійснювати видаткові операції.',

        'Для визначення такого поточного рахунку у банку фізична особа-боржник має звернутись до органу державної виконавчої служби, приватного виконавця, який наклав арешт на кошти фізичної особи-боржника, із заявою про визначення поточного рахунку у банку для здійснення видаткових операцій.',

        'Заява подається:',

        '· в паперовій формі (нарочно або засобами поштового зв’язку);',

        '· або в електронній формі з дотриманням вимог, встановлених Законом України «Про електронні документи та електронний документообіг».',

        'У заяві обов’язково зазначаються:',

        '· номер поточного рахунку, який фізична особа-боржник просить визначити для здійснення видаткових операцій,',

        '· найменування банку, в якому відкрито такий рахунок.',

        'Фізична особа-боржник несе відповідальність за достовірність зазначеної у заяві інформації.',

        'Державний, приватний виконавець протягом двох робочих днів з дня отримання відповідної заяви виносить постанову про визначення поточного рахунку фізичної особи-боржника у банку для здійснення видаткових операцій та невідкладно, не пізніше наступного робочого дня після винесення постанови:',

        '· надає/надсилає відповідну постанову банку (обслуговуючому банку);       ',

        '· перевіряє наявність інших виконавчих проваджень, відкритих стосовно фізичної особи-боржника, та, у разі якщо стосовно останнього відкриті інші виконавчі провадження, одночасно надає/надсилає відповідну постанову до органів державної виконавчої служби або приватному виконавцю, які здійснюють примусове виконання у таких виконавчих провадженнях.',

        'У такому разі поточний рахунок, зазначений у відповідній постанові, вважається визначеним для здійснення видаткових операцій і в межах таких виконавчих проваджень.',

        'Зупинення вчинення виконавчих дій або зупинення виконавчого провадження не є підставою для відмови у визначенні поточного рахунку для здійснення видаткових операцій.',

        'У разі надходження від державного, приватного виконавця постанови про визначення поточного рахунку фізичної особи-боржника для здійснення видаткових операцій банк за визначеним у постанові рахунком здійснює видаткові операції на суму коштів у розмірі, встановленому цим підпунктом.',

        'Крім цього, змінами передбачено, що юридичні особи-боржники, самозайняті особи (які використовують найману працю фізичних осіб), на кошти яких накладено арешт органами державної виконавчої служби, приватними виконавцями, можуть здійснювати видаткові операції з поточних рахунків виключно для виплати заробітної плати в розмірі не більше п’яти розмірів мінімальної заробітної плати на місяць на одного працівника такої юридичної особи чи самозайнятої особи, а також для сплати податків, зборів та єдиного внеску на загальнообов’язкове державне соціальне страхування.',

        'Контроль за дотриманням встановлених законом вимог під час здійснення виплат покладається на службових осіб юридичної особи-боржника, самозайняту особу відповідно.',

        'Також варто звернути увагу, що новою редакцією пункту 10-2 розділу XIII «Прикінцеві та перехідні положення» Закону України «Про виконавче провадження» не передбачено:',

        '· припинення звернення стягнення на заробітну плату у період дії в Україні воєнного стану (звернення стягнення припиняється виключно на пенсію та стипендію, крім рішень про стягнення аліментів, про відшкодування шкоди, заподіяної каліцтвом, іншим ушкодженням здоров’я або смертю внаслідок кримінального правопорушення, та рішень, боржниками за якими є громадяни російської федерації);',

        '· заборони примусового виконання виконавчих написів нотаріусів (крім відкриття виконавчих проваджень на підставі виконавчих написів нотаріусів, вчинених на кредитних договорах, які не посвідчені нотаріально).',

        'Окрім цього слід зазначити, що Законом № 3048-ІХ внесені зміни до пункту 6-1 розділу IV «Прикінцеві та перехідні положення» Закону України «Про органи та осіб, які здійснюють примусове виконання судових рішень і рішень інших органів», якими врегульовані питання діяльності приватних виконавців у період дії в Україні воєнного стану та, зокрема, передбачена можливість:',

        '· зупинення Міністерством юстиції діяльності приватного виконавця, який не здійснює свою діяльність в офісі, перебуває за межами України або проходить військову службу відповідно до Закону України «Про військовий обов’язок і військову службу» та визначення приватного виконавця, який здійснюватиме його заміщення;',

        '· зміни виконавчого округу приватним виконавцем, який має на виконанні незакінчені виконавчі провадження, та офіс якого розташований у територіальній громаді, що належить до територій, на яких ведуться активні бойові дії, або тимчасово окупованих територій, та продовження примусового виконання виконавчих документів, що перебували у нього на виконанні.',

        'Інформація станом на 12 травня 2023 року',
      ],
      imgs: [ papers ],
    },
    {
      date: '12.08.22',
      title: 'Україна та Міжнародний валютний фонд продовжують вести дискусії щодо нової кредитної програми.',
      paragraphList: [
        ' "На початку серпня Україна звернулась до МВФ із офіційним запитом щодо започаткування нової програми кредитування. На нашу думку, її основними цілями в поточних умовах має бути: покриття розриву платіжного балансу, забезпечення макроекономічної стабільності та продовження здійснення структурних економічних реформ, в тому числі, націлених на набуття Україною статусу члена ЄС. Наразі продовжуються консультації між органами державної влади та експертами МВФ", - сказав Ніколайчук. ',
        'За його інформацією, "всередині МВФ йдуть жваві дискусії щодо того, який саме формат програми найбільше підходить для України". Також точаться дискусії щодо зміни інструментів, які використовує МВФ для підтримки країн, які, власне, й потребують підтримки.',
        '"Ми сподіваємось, що за результатами цих дискусій вже будуть повідомлення про формати підтримки з боку МВФ України в цих умовах. У МВФ немає заборони на кредитування країн, які перебувають у стані війни", - зауважив Ніколайчук.',
      ],
      imgs: [ fond ],
    },
    {
      date: '18.03.22',
      title: 'Нові правила роботи банків і небанківських фінансових установ, що займаються кредитуванням, під час війни',
      paragraphList: [
        'Національний банк України вітає ухвалення Закону України № 2120-IX  "Про внесення змін до Податкового кодексу України та інших законодавчих актів України щодо дії норм на період дії воєнного стану" (далі – закон).',
        'Новий закон змінює низку правил роботи банків та небанківських фінансових установ, зокрема тих, що надають послуги з кредитування. Мета цих змін – насамперед полегшити ситуацію для українців-позичальників на час війни та післявоєнного відновлення економіки.',
        'Для цього новий закон передбачає, що на час дії воєнного стану та в тридцятиденний строк після дня його припинення або скасування споживач не буде нести відповідальності перед кредитодавцем у разі прострочення виконання зобов’язань за споживчим кредитом.',
        'Крім того, у разі допущення такого прострочення споживач звільняється, зокрема, від обов’язку сплати кредитодавцю неустойки (штрафу, пені) та інших платежів, сплата яких передбачена договором про споживчий кредит за прострочення виконання (невиконання, часткове виконання) споживачем зобов’язань за таким договором.',
        'Також забороняється у разі невиконання зобов’язань за договором про споживчий кредит збільшення процентної ставки за користування кредитом, крім випадків, коли встановлення змінюваної процентної ставки передбачено кредитним договором чи договором про споживчий кредит.',
        'Водночас звертаємо увагу, що неустойка (штраф, пеня) та інші платежі, сплата яких передбачена договором про споживчий кредит, нараховані включно з 24 лютого 2022 року за прострочення виконання за таким договором, підлягають списанню.',
        'Важливо, що нові правила не передбачають скасування відсотків за користування кредитними коштами. Таке нарахування є правомірним з боку кредитора. Кредитні канікули – це відтермінування сплати боргу, а не його прощення. Також кредитні канікули – це право кредитора, а не його зобов’язання. Саме тому рекомендуємо домовитися безпосередньо з кредитором про кредитні канікули. Однак, якщо позичальники мають достатній запас ресурсів, щоб продовжувати діяльність та обслуговувати позики, варто їх надалі сплачувати.',
      ],
      imgs: ['https://bank.gov.ua/admin_uploads/article/1280x720_%D0%9D%D0%BE%D0%B2%D1%96_%D0%BF%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0_%D1%80%D0%BE%D0%B1%D0%BE%D1%82%D0%B8_%D0%B1%D0%B0%D0%BD%D0%BA%D1%96%D0%B2_2022-03-18.jpg.webp?v=4'],
    },
  ]
}

export default newsData;