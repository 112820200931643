import React, { useState } from 'react';
import styled from './index.module.scss';
import Logo from '../logo/Logo';
import logo from '../../assets/logo-white.png';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import { MenuData } from '../../data';

interface Props {
  menuData: MenuData[];
}

export default function MobileNav({ menuData }: Props) {
  const { t } = useTranslation();

  const burgerClosed: string = styled.burger_bar + ' ' + styled.unclicked;
  const burgerOpen: string = styled.burger_bar + ' ' + styled.clicked;
  const menuHidden: string = styled.list + ' ' + styled.hidden;
  const menuVisible: string = styled.list + ' ' + styled.visible;

  const [ burger_class, setBurgerClass ] = useState<string>(burgerClosed);
  const [ menu_class, setMenuClass ] = useState(menuHidden);
  const [ isMenuClicked, setIsMenuClicked ] = useState<boolean>(false);
  
  const updateMenu = () => {
    if(!isMenuClicked) {
        setBurgerClass(burgerOpen)
        setMenuClass(menuVisible)
    }
    else {
        setBurgerClass(burgerClosed)
        setMenuClass(menuHidden)
    }
    setIsMenuClicked(!isMenuClicked)
  }

  return (
    <div className={styled.mobileNav}>
        <div className={styled.mobileNav__container}>
          <Logo 
            to={"/"} 
            src={logo} 
            alt="Interrisk logo"
            className={''}
          />

          <div className={styled.burger_menu} onClick={updateMenu}>
            <div className={burger_class} ></div>
            <div className={burger_class} ></div>
            <div className={burger_class} ></div>
          </div>
        </div>
        {/* // nav list */}
        <ul 
          className={ menu_class }
          >
          
        { menuData.map((el: MenuData) => (

          // nav list item
          <li 
            key={ el.name } 
            className={ styled.item }
          > 
            <Link 
              className={ styled.link } 
              to={el.to}
              onClick={updateMenu}
            > 

              {/* link text */}
              { t(el.name) } 
            </Link> 
          </li>

          ))}
        </ul>
    </div>
  )
}
