import React from 'react';
import styled from './index.module.scss';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export default function CustomContainer({ children, style, className }: Props) {
  return (
    <div 
      className={`${ styled.container } ${ className }`}
      style={style}
    >
      { children }
    </div>
  )
}
