import React, { useState } from 'react';
import styled from './index.module.scss';
import i18n from '../../i18n/i18n';
// import { useLocation, useParams } from 'react-router-dom';

export default function LanguageSelector() {
  const active: string = styled.button + ' ' + styled.active;
  const inactive: string = styled.button;

  const [ uk, setUk ] = useState<boolean>(i18n.language === 'uk' );
  const [ pl, setPl ] = useState<boolean>(i18n.language === 'pl' );
  const [ en, setEn ] = useState<boolean>(i18n.language === 'en' );

  const changeLanguage = (language: string) => {
    if (language === 'uk') {
      setUk(true);
      setEn(false);
      setPl(false);
    }

    if (language === 'en') {
      setUk(false);
      setEn(true);
      setPl(false);
    }

    if (language === 'pl') {
      setUk(false);
      setEn(false);
      setPl(true);
    }

    i18n.changeLanguage(language);
  }

  
  return (
    <div style={{display: "flex"}}>
        <button 
          onClick={() => changeLanguage('uk')}
          className={ `${uk ? active : inactive}` }
        >
          ua
        </button>

        <button 
          onClick={() => changeLanguage('pl')}
          className={ `${pl ? active : inactive}` }
        >
          pl
        </button>

        <button 
          onClick={() => changeLanguage('en')}
          className={ `${en ? active : inactive}` }
        >
          en
        </button>
    </div>
  )
}
