import { ServicesSectionData } from "../components/servicesSection/ServicesSection";

const servicesService1: ServicesSectionData = {
  title: 'services-service-1-title',
  textList: [ 
    'services-service-1-list-left-item-1',
    'services-service-1-list-left-item-2',
  ],
  id: 'section-1',
  to: '#section-1',
  buttonText: 'services-service-button-text',
  list: [
    'services-service-1-list-right-item-1',
    'services-service-1-list-right-item-2',
    'services-service-1-list-right-item-3',
    'services-service-1-list-right-item-4',
    'services-service-1-list-right-item-5',
  ],
  imgClass: '_icon-suitcase-bag',
}

export default servicesService1;