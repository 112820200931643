import gif1 from '../assets/circles/1.gif';
import gif2 from '../assets/circles/2.gif';
import gif4 from '../assets/circles/4.gif';

import serv1 from '../assets/circles/serv1.png';
import serv2 from '../assets/circles/serv2.png';
import serv4 from '../assets/circles/serv4.png';

export interface SelectServiceDataItem {
  gif: string;
  img: string;
  title: string;
  to: string;
  text: string;
}

export interface SelectServiceData {
  title: string;
  description: string;
  list: SelectServiceDataItem[];
}

const selectServiceData = {
  title: 'select-service-title',
  description: 'select-service-description',
  list: [
    {
      gif: gif1,
      img: serv1,
      title: 'select-service-1-title',
      to: '/services',
      text: 'select-service-1-text',
    },
    {
      gif: gif2,
      img: serv2,
      title: 'select-service-2-title',
      to: '/services',
      text: 'select-service-2-text',
    },
    // {
    //   gif: gif3,
    //   img: serv3,
    //   title: 'select-service-3-title',
    //   to: '/services',
    //   text: 'select-service-3-text',
    // },
    {
      gif: gif4,
      img: serv4,
      title: 'select-service-4-title',
      to: '/services',
      text: 'select-service-4-text',
    }
  ]

}

export default selectServiceData;
