import isoimg from '../assets/isoStandart.jpg';
import nbr from '../assets/nbrLogo.png';

export interface AbouUsData {
  id: string;
  title: string;
  text: string;
  listTitle: string;
  list: {img?: string, text: string, number?: string, percent?: true}[]
}

export const abouUsData: AbouUsData = {
  id: 'section-1',
  title: 'about-us-title',
  text: 'about-us-text',
  listTitle: 'about-us-list-title',
  list: [
    {
      img: isoimg,
      text: 'about-us-list-0'
    },
    {
      number: '10',
      text: 'about-us-list-1'
    },
    {
      number: '12',
      text: 'about-us-list-2'
    },{
      number: '99',
      percent: true,
      text: 'about-us-list-3'
    },
    {
      img: nbr,
      text: 'about-us-list-4'
    }
  ]
}
