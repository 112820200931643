import React from 'react';
import styled from './index.module.scss';
import { useNavigate } from 'react-router-dom';

export interface PostProps {
  imgs: string[];
  title: string;
  paragraphList: string[];
  mode?: "closed" | "opened";
  links?: string[];
  date: string;
  index?: number;
  type?: "news" | "publications"
}

function Post({
  paragraphList,
  title,
  imgs,
  mode="closed",
  date,
  index,
  type,
}: PostProps) {
  function textCutter(arr: string[]): string {
    return arr.join('').substring(0, 300).trimEnd() + '...';
  }
  const navigate = useNavigate();

  if ( mode === "closed" ) {
    return (
      <div className={ styled.closed }>
        <p>{ date }</p>
        <img src={ imgs[0] } alt={title}/>
        <h4> { title } </h4>
        <p> { textCutter( paragraphList ) } </p>
        <button onClick={()=>navigate(`${type}/${index}`)}> Читати більше </button>
      </div>
    )
  }
  return (
    <div className={ styled.opened }>
      <div>
        <button 
          onClick={()=>navigate(-1)}
        > Назад </button>
        <p>{ date }</p>
      </div>
      { imgs.length === 1 && (<img src={ imgs[0] } alt={title}/>) }
      { imgs.length >= 2 && (<img src={ imgs[0] } alt={title}/>) }
      <h4> { title } </h4>
      { paragraphList.map((p: string, i: number) => (
        <p key={i}> { p } </p>
      ))}
    </div>
  )
}

export default Post;
