import React from 'react';
import FirstBlock from '../../components/FirstBlock';
import firstPressCenterData from '../../data/firstPressCenter';
import Posts from '../../components/posts/Posts';
import newsData from '../../data/newsData';
import publicationsData from '../../data/publicationsData'

export default function PressCenter() {
  return (
    <div 
      className='background-pages' 
      style={{
        flex: "1 0 auto"
      }}>
      <FirstBlock data={ firstPressCenterData }/>
      <Posts data={ newsData }/>
      <Posts data={ publicationsData }/>
    </div>
  )
}
