import React from 'react'
import { useTranslation } from 'react-i18next';

interface Props {
  titleColor?: string;
  descriptionColor?: string;
  positionText: 'start' | 'end' | 'center';
  description?: string;
  title: string;
}

function H3Component({ 
  titleColor='custom-primary-color',
  descriptionColor='text-black-50',
  positionText,
  description,
  title,
}: Props) {
  const { t } = useTranslation();
  const h3Class: string = `${titleColor} text-${positionText} `
    + 'fs-1 pt-5 pb-3';

  const pClass: string = `${descriptionColor} text-${positionText} fw-semibold`;

  return (
    <>
      <h3 className={ h3Class }>
        { t(title) }
      </h3>
      { description && (
        <p className={ pClass }>
          { t(description) }
        </p>
      )}
    </>
  )
}

export default H3Component;
