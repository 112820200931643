import React from 'react';
import Slider from '../../components/Carousel';
import SelectService from '../../components/SelectService';
import sliderData from '../../data/sliderData';
import selectServiceData from '../../data/selectServiceData';
import OursClientsCarousel from '../../components/OursClientsCarousel';
import oursClientsData from '../../data/oursClientsData';
import ourTeamData from '../../data/ourTeamData';
import OurTeam from '../../components/oursTeam/OurTeam';


export default function Main() {
  return (
    <div className='background-pages' >
      <Slider slides={ sliderData }/>
      <SelectService 
        id={'#selectService'} 
        data={ selectServiceData } 
      />
      <OursClientsCarousel 
        id={'#oursClients'} 
        data={ oursClientsData } 
      />
      <OurTeam data={ ourTeamData } id="ourTeam"/>
    </div>
  )
}
