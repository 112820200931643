import React from 'react';
import styled from './index.module.scss'
import DropDown from '../dropdown/Dropdown';
import { MenuData } from '../../data';
import CustomContainer from '../customContainer/CustomContainer';

interface Props {
  menuData: MenuData[];
}

export default function TabletNav({ menuData }: Props) {
  return (
    <div className={styled.container}>
      <CustomContainer style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
      }}>
        { menuData.map((el: MenuData) => (
          <DropDown 
            list={el.list} 
            to={el.to}
            name={el.name}
            key={el.name}
            />
        )) }
      </CustomContainer>
    </div>
  )
}
