import React from 'react';
import styled from './index.module.scss';

interface Props {
  phone?: string
  email?: string
}

export default function ContactLink({ phone, email }: Props) {
  return (<>
    { phone && (
      <a 
        href={"tel:" + phone} 
        className={`${styled.link} ${styled.phone}`}
      >
        { phone }
      </a>
    )}

    { email && (
      <a 
        href={"mailto:" + email} 
        className={`${styled.link} ${styled.email}`}
      >
        { email }
      </a>
    )}
  </>)
}

