import { ServicesSectionData } from "../components/servicesSection/ServicesSection";

const servicesService3: ServicesSectionData = {
  title: 'services-service-3-title',
  textList: [ 
    'services-service-3-list-left-item-1',
  ],
  id: 'section-3',
  to: '#section-3',
  buttonText: 'services-service-button-text',
  list: [
    'services-service-3-list-right-item-1',
    'services-service-3-list-right-item-2',
    'services-service-3-list-right-item-3',
    'services-service-3-list-right-item-4',
    'services-service-3-list-right-item-5',
    'services-service-3-list-right-item-6',
    'services-service-3-list-right-item-7',
  ],
  imgClass: '_icon-balance',
}

export default servicesService3;