import React from 'react'
import CustomContainer from '../customContainer/CustomContainer';
import Heading from '../heading/Heading';
import Post, { PostProps } from '../post/Post';
import styled from './index.module.scss';

interface PostListProps {
  postList: PostProps[],
  type: 'publications' | 'news',
}

function PostList({ postList, type }: PostListProps) {
  return (
    <div className={ styled.list_container }>
      { postList.map((post: PostProps, i: number) => (
        <Post
          key={ i }
          index={ i }
          imgs={ post.imgs }
          title={ post.title }
          date={ post.date }
          paragraphList={ post.paragraphList }
          type={ type }
        /> 
      ))}
    </div>
  )
}

export interface PostsData {
  id: string;
  title: string;
  type: 'publications' | 'news'
  postList: PostProps[];
}

interface PostsProps {
  data: PostsData;
}

function Posts({ data }: PostsProps) {
  return (
    <div id={ data.id }>
      <CustomContainer>
        <Heading heading={ data.title } mode={'h3'} />
        <PostList postList={ data.postList } type={ data.type }/>
      </CustomContainer>
    </div>
  )
}

export default Posts;
