import React from 'react';
import useMediaQuery from '../hooks/useMediaQuery';
import MobileNav from './mobileNav/MobileNav';
import TabletNav from './tabletNav/TabletNav';
import { menuData } from '../data';

export default function BottomNav() {
  const isTablet: boolean = useMediaQuery('(min-width: 960px)');

  return (
    <div className='position-sticky sticky-top' id="header">
      {isTablet && (<TabletNav menuData={ menuData }/>)}
        
      {!isTablet && (<MobileNav menuData={ menuData }/>)}
    </div>
  )
}
