import React from 'react'
import CustomContainer from './customContainer/CustomContainer';
import { useParams } from 'react-router-dom';
import newsData from '../data/newsData';
import publicationsData from '../data/publicationsData';
import Post from './post/Post';

export default function NewsFullScreen() {
  const params = useParams();
  const newsIndex: number = +(params.index ? params.index : 0)
  const type: string = params.type ? params.type : 'news'

  const { 
    date, 
    title, 
    paragraphList, 
    imgs 
  } = type === 'news' 
    ? newsData.postList[newsIndex] 
    : publicationsData.postList[newsIndex];

  return (
    <div>
      <CustomContainer>
        <Post 
          mode="opened" 
          date={date} 
          paragraphList={paragraphList} 
          title={title}
          imgs={imgs}
        />
      </CustomContainer>
    </div>
  )
}
