import React from 'react';
import CustomContainer from './customContainer/CustomContainer';
import Heading from './heading/Heading';
import useMediaQuery from '../hooks/useMediaQuery';
import LinkList, { ItemProps } from './linkList/LinkList';
import DescriptionList from './descriptionList/DescriptionList';
import { useTranslation } from 'react-i18next';

export interface FirstBlockData {
  data: {
    title: string;
    descriptionList: string[];
    linkList: ItemProps[]
  };
  id?: string;
  background: string;
}

interface Props {
  data: FirstBlockData;
}

function FirstBlock({ data }: Props) {
  const isDesk = useMediaQuery('(min-width: 1280px)');
  const { t } = useTranslation();
  
  return (
    <div
      id={ data.id }
      style={{
        background: `url(${ data.background })`,
        backgroundPosition: '50% 50% ',
        width: '100%',
        boxShadow: '0 0 0 999px rgba(1,42,88,.6) inset',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      }}
    >
      <CustomContainer>
        <Heading 
          color="white" 
          heading={ t(data.data.title) } 
          mode="h2"
          textAling={ !isDesk ? "center" : "start" }
        />
        <div style={{ paddingBottom: "40px"}}>

          <DescriptionList
            textAling={ !isDesk ? "center" : "start" }
            list={ data.data.descriptionList }
          />

        </div>

        <div style={{ paddingBottom: "50px"}}>
          <LinkList data={ data.data.linkList }/>
        </div>
      </CustomContainer>
    </div>
  )
}

export default FirstBlock;
