import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

import transUk from './locales/uk/translation.json';
import transEn from './locales/en/translation.json';
import transPl from './locales/pl/translation.json';

const resources = {
  uk: {
    translation: transUk,
  },
  pl: {
    translation: transPl,
  },
  en: {
    translation: transEn,
  },
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
  debug: false, // disable in prod
  // lng: 'uk', 
  fallbackLng: 'uk', // def lang
  resources,
  detection: {
    order: ['cookie',],
    caches: ['cookie'],
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
