import background from '../assets/corousel1/pexels-photo-97130-min.jpg';
import { FirstBlockData } from '../components/FirstBlock';

export const firstServicesData: FirstBlockData = {
  id: "section-0",
  background: background,
  data: {
    title: 'first-services-title',
    descriptionList: [
      'first-services-description-1',
      'first-services-description-2',
      'first-services-description-3',
    ],
    linkList: [
      {
        to: '#section-1',
        fontClass: '_icon-suitcase-bag',
        name: 'first-services-link-1',
      },
      {
        to: '#section-2',
        fontClass: '_icon-call-support',
        name: 'first-services-link-2',
      },
      {
        to: '#section-3',
        fontClass: '_icon-balance',
        name: 'first-services-link-3',
      },
    ]
  }
}

export default firstServicesData;