import img4 from '../assets/corousel1/27502-min.jpg';
import img2 from '../assets/corousel1/OGMT9J0-min.jpg';
import img3 from '../assets/corousel1/kiev-at-night.jpg';
import img1 from '../assets/corousel1/evening_kyiv.jpg';


const sliderData: SliderData[] = [
  {
    img: img1,
    header: 'slider-1-slide-0-header',
    text: 'slider-1-slide-0-text',
    to: '/services',
  },
  {
    img: img2,
    header: 'slider-1-slide-1-header',
    text: 'slider-1-slide-1-text',
    to: '/services',
  },
  {
    img: img3,
    header: 'slider-1-slide-2-header',
    text: 'slider-1-slide-2-text',
    to: '/services',
  },
  {
    img: img4,
    header: 'slider-1-slide-3-header',
    text: 'slider-1-slide-3-text',
    to: '/services',
  },
]


export interface SliderData {
  img: string;
  header: string;
  text: string;
  to: string;
}

export default sliderData;