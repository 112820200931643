import React from 'react';
import { Advice, AdvicesForDebtorData } from '../data/advicesForDebtorData';
import SectionContainer from './SectionContainer';
import AdviceForDebtor from './AdviceForDebtor';

interface Props {
  id: string;
  data: AdvicesForDebtorData;
}

export default function AdvicesForDebtor({ id, data }: Props) {
  return (
    <SectionContainer
      id={id}
      title={data.title}
      mode='h3' 
      positionText='start'
    >
      <div className='d-flex flex-wrap mb-4'>
        { data.advices.map((advice: Advice) => (
          <AdviceForDebtor
            key={ advice.title }
            img={ advice.img }
            title={ advice.title }
            text={ advice.text }
          />
        ))}
      </div>
    </SectionContainer>
  )
}
