import React from 'react';
import CustomContainer from '../customContainer/CustomContainer';
import styled from './index.module.scss';
import LinkButton from '../linkButton/LinkButton';
import Modal from '../modal/Modal';
import { useTranslation } from 'react-i18next';

export interface ServicesSectionData {
  background?: string;
  title: string;
  textList: string[];
  id: string;
  to: string;
  buttonText: string;
  list: string[];
  imgClass: string;
}

interface Props {
  data: ServicesSectionData;
}

function ServicesSection({ data }: Props) {
  const backgroundStyle: React.CSSProperties = data.background 
    ? { backgroundImage: `url(${data.background})` } 
    : {};
    
  const { t } = useTranslation();

  return (
    <div 
      style={backgroundStyle} 
      id={ data.id }
    >
      <CustomContainer>
        <div className={ styled.topContainer }>
          <div className={ `${data.imgClass} ${ styled.image }` }></div>
          <h6 
            className={ data.background
              ? styled["white"] 
              : styled["blue"]
            }
          > { t(data.title) } </h6>
        </div>

        <div className={ styled.contentContainer }> 
          <div>
            { data.textList.map((text: string, index: number) => (
              <p 
                key={ index }
                className={ data.background && styled["white"]
                } 
              >
                { t(text) }
              </p>
            ))}

            <Modal mode="form">
              <LinkButton
                className={ styled.link }
                to={ data.to } 
                text={ t(data.buttonText) }
                mode={ data.background ? "white" : "blue" }
              />
            </Modal>
          </div>

          <div>
              { data.list.map((text: string, index: number) => (
                <p 
                  className={ 
                    data.background
                      ? styled["white"] 
                      : styled["blue"]
                  } 
                  key={index}
                > 
                  { `${ index + 1 }. ${ t(text) }` } 
                </p>
              ))}
          </div>
        </div>
      </CustomContainer>
    </div>
  )
}

export default ServicesSection;
