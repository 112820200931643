import img1 from '../assets/tip/tip-1.png';
import img2 from '../assets/tip/tip-2.png';
import img3 from '../assets/tip/tip-3.png';
import img4 from '../assets/tip/tip-4.png';
import img5 from '../assets/tip/tip-5.png';
import img6 from '../assets/tip/tip-6.png';

export interface Advice {
  img: string;
  title: string;
  text: string;
}

export interface AdvicesForDebtorData {
  title: string;
  advices: Advice[];
}

const advicesForDebtorData: AdvicesForDebtorData = {
  title: 'advices-to-the-debtor',
  advices: [
    {
      img: img1,
      title: 'advices-to-the-debtor-1-title',
      text: 'advices-to-the-debtor-1-text',
    },
    {
      img: img2,
      title: 'advices-to-the-debtor-2-title',
      text: 'advices-to-the-debtor-2-text',
    },
    {
      img: img3,
      title: 'advices-to-the-debtor-3-title',
      text: 'advices-to-the-debtor-3-text',
    },
    {
      img: img4,
      title: 'advices-to-the-debtor-4-title',
      text: 'advices-to-the-debtor-4-text',
    },
    {
      img: img5,
      title: 'advices-to-the-debtor-5-title',
      text: 'advices-to-the-debtor-5-text',
    },
    {
      img: img6,
      title: 'advices-to-the-debtor-6-title',
      text: 'advices-to-the-debtor-6-text',
    }
  ]
}

export default advicesForDebtorData;
