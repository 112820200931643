import React from 'react';
import logo from '../../assets/logoBlue.png';
import styled from './index.module.scss';
import LanguageSelector from '../languageSelector/LanguageSelector';
import Logo from '../logo/Logo';
import useMediaQuery from '../../hooks/useMediaQuery';
import ContactLink from '../contactLink/ContactLink';
import {email, phone, phoneHotline} from '../../data/contactsData';

export default function TopNav() {
  const isMobile = useMediaQuery('(min-width: 960px)');

  return (
    <div className={styled.topNav}>
      <div className={styled.container}>
        { isMobile && (
          <Logo 
            to="/"
            src={logo} 
            alt="Interrisk logo"
            className={styled.logo}
          />
        )}

        <div className={ styled.numbers }>
          <ContactLink phone={ phone } />

          <ContactLink phone={ phoneHotline } />
        </div>

        { isMobile && (
          <ContactLink email={ email } />
        )}

        <LanguageSelector/>
      </div>
    </div>
  )
}
