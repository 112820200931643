import React from 'react';
import CustomContainer from './customContainer/CustomContainer';
import H2Component from './H2Component';
import H3Component from './H3Component';

interface Props {
  id: string;
  mode: "h2" | "h3"
  style?: React.CSSProperties;
  titleColor?: string;
  descriptionColor?: string;
  children: React.ReactNode;
  positionText: 'start' | 'end' | 'center';
  descriptionList?: string[];
  description?: string;
  title: string;
}

function SectionContainer({ 
  id,
  mode,
  style,
  titleColor,
  descriptionColor,
  children,
  positionText,
  descriptionList,
  description,
  title,
}: Props) {
  return (
    <div
      id={ id }
      
      style={ style }
    >
      <CustomContainer>
        {/* h3 mode */}
        { mode === "h3" && (
          <H3Component 
            title={ title }
            description={ description }
            positionText={ positionText }
            titleColor={ titleColor }
            descriptionColor={ descriptionColor }
          />
        )}

        {/* h2 mode */}
        { mode === "h2" && (
          <H2Component 
            title={ title }
            descriptionList={ descriptionList }
            positionText={ positionText }
            titleColor={ titleColor }
            descriptionColor={ descriptionColor }
          />
        )}

        {/*Content */}
        { children }
      </CustomContainer>
    </div>
  )
}

export default SectionContainer;
