import React from 'react';
import advicesForDebtorData from '../../data/advicesForDebtorData';
import AdvicesForDebtor from '../../components/AdvicesForDebtor';
import FirstBlock from '../../components/FirstBlock';
import { debtorData } from '../../data/firstForDebtorData';
import Qa from '../../components/qa/Qa';
import qaData from '../../data/qaData';
import NbuRequirements from "../../components/nbuRequirements/NbuRequirements";
import nbuReqData from "../../data/nbuReqData";

export default function ForDebtor() {
  return (
    <div className='background-pages' >
      <FirstBlock data={ debtorData }/>
      <Qa data={ qaData } id="section-1"/>
      <AdvicesForDebtor id="section-2" data={ advicesForDebtorData } />
      <NbuRequirements id="section-3" data={ nbuReqData }/>
    </div>
  )
}
