import React from 'react';
import { OursClientsData } from '../data/oursClientsData';
import { Carousel } from 'react-bootstrap';
import useMediaQuery from '../hooks/useMediaQuery';
import H3Component from './H3Component';
import CustomContainer from './customContainer/CustomContainer';

import company1 from '../assets/clientsLogos/company1.png';
import company2 from '../assets/clientsLogos/company2.png';
import company3 from '../assets/clientsLogos/company3.png';
import company4 from '../assets/clientsLogos/company4.png';
import company5 from '../assets/clientsLogos/company5.png';
import company6 from '../assets/clientsLogos/company6.png';
import company7 from '../assets/clientsLogos/company7.png';
import company8 from '../assets/clientsLogos/company8.png';
import company9 from '../assets/clientsLogos/company9.png';
import company10 from '../assets/clientsLogos/company10.png';
import company11 from '../assets/clientsLogos/company11.png';
import company12 from '../assets/clientsLogos/company12.png';
import company13 from '../assets/clientsLogos/company13.png';
import company14 from '../assets/clientsLogos/company14.png';
import company15 from '../assets/clientsLogos/company15.png';
import company16 from '../assets/clientsLogos/company16.png';
import company17 from '../assets/clientsLogos/company17.png';
import company18 from '../assets/clientsLogos/company18.png';
// import company19 from '../assets/clientsLogos/company19.png';
import company20 from '../assets/clientsLogos/company20.png';


import pumb from '../assets/clientsLogos/pumb.svg';
import privat from '../assets/clientsLogos/Privat-Bank-01.svg';
import oschad from '../assets/clientsLogos/oschad.svg';
import fondGarantyvanya from '../assets/clientsLogos/fondGarantyvanya.png';
import img from '../assets/partners.jpg';
import vuso from '../assets/clientsLogos/vuso_company.svg';
import arrowKredobank from '../assets/clientsLogos/arrowLogo.svg';
import textKredobank from '../assets/clientsLogos/textLogo.svg';

interface Props {
  id: string;
  data: OursClientsData;
}

const items: JSX.Element[] = [
  (<div 
    style={{ 
      "display": "flex", 
      "alignItems": 
      "center", 
      "height": "100%", 
      "width": "100%" 
    }}
  >
    <img src={arrowKredobank} alt="arrowKredobank" style={{ "display": "block", "marginRight": "5%", "marginBottom": "7%", "maxHeight": "30%", "maxWidth": "20%"}}/>
    <img src={textKredobank} alt="textKredobank" style={{ "display": "block", "maxHeight": "100%", "maxWidth": "75%"}} />
  </div>),
  (<img 
      src={vuso} 
      alt="clientsLogos" 
      style={{
        "display": "block",
        "height": "90%",
        "width": "80%",
      }}
  />),
  (<img 
    src={pumb} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "height": "70%",
      "width": "70%",
    }}
  />),
  (<img 
    src={privat} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "height": "75%",
      "width": "100%",
    }}
  />),
  (<img 
    src={oschad} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "height": "100%",
      "width": "80%",
    }}
  />),
  (<img 
    src={fondGarantyvanya} 
    alt="clientsLogos" 
    style={{
      "display": "inline-block",
      "height": "190px",
      "width": "190px"
    }}
  />),
  (<img 
    src={company1} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company2} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company3} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company4} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company5} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company6} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company7} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company8} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company9} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company10} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company11} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company12} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company13} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company14} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company15} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company16} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company17} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company18} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<img 
    src={company20} 
    alt="clientsLogos" 
    style={{
      "display": "block",
      "maxHeight": "100%",
      "maxWidth": "100%",
    }}
  />),
  (<div style={{ "marginLeft": "20px", "textAlign": "center"}} >
    <p style={{ "color": "white", "fontSize": "26px", "fontWeight": "bold" }}>Фінансова <span style={{ "textTransform": "uppercase", "fontWeight": "normal" }}> компанія </span>"ЕЛ.ЕН.ГРУП"</p>
  </div>),
  (<div style={{ "marginLeft": "20px", "color": "white",  "textAlign": "center", "fontSize": "26px"}} >
    <p style={{ "fontWeight": "bold" }}>ФК «ОМЕГА ФАКТОР»</p>
    <p style={{ "fontSize": "16px", "textAlign": "center"}}>ТОВАРИСТВО З ОБМЕЖЕНОЮ ВІДПОВІДАЛЬНІСТЮ</p>
  </div>),
  (<div style={{ "marginLeft": "20px", "color": "white",  "textAlign": "center", "fontSize": "26px"}} >
    <p style={{ "fontWeight": "bold" }}>ФК «СОНАТІ»</p>
  </div>)
]

// export default function OursClientsCarousel({ id, data }: Props) {
//   function slider( number: number ): string[][] {
//     const res: string[][] = [];

//     for (let i = 0; i < data.clientsLogos.length; i += number) {
//       if (i + number <= data.clientsLogos.length - 1) {
//         res.push(data.clientsLogos.slice(i, i + number))
//       } else {
//         res.push(data.clientsLogos.slice(i, -1))
//       }
//     }

//     return res;
//   }

//   const isTablet = useMediaQuery('(min-width: 960px)');
//   const fourByFour: string[][] = slider( 4 );
//   const sixBySix: string[][] = slider( 9 );

//   return (
//     <div
//       id={ id }
//       style={{
//         backgroundSize: 'cover',
//         height: '800px',
//         background: `url(${ data.background })`,
//         boxShadow: '0 0 0 999px rgba(1,42,88,.6) inset',
//       }}
//     >

//       <CustomContainer>
//         <H3Component 
//           title={data.title}
//           positionText='center'
//           titleColor='text-white'
//         />
//       </CustomContainer>
      
//       <Carousel
//         className=''
//       >
//         { (isTablet ? sixBySix : fourByFour )
//             .map((slide: string[], i: number) => (

//           <Carousel.Item key={ i } >
//               <CustomContainer 
//                 style={{
//                   height: isTablet ? '670px' : '620px',
//                 }}
//               >
//                 <div 
//                   className='w-100 d-flex flex-wrap h-100'
//                 >
//                     { slide.map((img: string, i: number) => (
//                       <div
//                         style={{
//                           width: isTablet ? "33%" : "50%",
//                           height: isTablet ? "33%" : "50%",
//                           display: "flex",
//                           justifyContent: "center",
//                           alignItems: "center",
//                         }}
//                         key={i}
//                       >
//                         <img 
//                           src={img} 
//                           alt="clientsLogos" 
//                           style={{
//                             "display": "block",
//                             "maxHeight": "100%",
//                             "maxWidth": "100%",
//                           }}
//                         />
//                       </div>
//                     )) }
//                 </div>
//               </CustomContainer>
//           </Carousel.Item>
//         ))}
//       </Carousel>
//     </div>
//   )
// }

function slider( size: number, arr: JSX.Element[] ): JSX.Element[][] {
  const res: JSX.Element[][] = [];
  const length: number = arr.length;

  for (let i = 0; i < length; i += size) {
    let j: number = 0;

    if (i + size < length) {
      j = size + i;
    } else {
      j = length - j;
    }

    res.push(arr.slice(i, j))
  }

  return res;
}

export default function OursClientsCarousel({ id, data }: Props) {
  const isTablet = useMediaQuery('(min-width: 960px)');
  const _3_x_3: JSX.Element[][] = slider(9, items);
  const _2_x_2: JSX.Element[][] = slider(4, items);

  return (
    <div
      id={ id }
      style={{
        backgroundSize: 'cover',
        height: '800px',
        background: `url(${ img })`,
        boxShadow: '0 0 0 999px rgba(1,42,88,.6) inset',
      }}
    >

      <CustomContainer>
        <H3Component 
          title='ours-clients-title'
          positionText='center'
          titleColor='text-white'
        />
      </CustomContainer>
      
      <Carousel
        className=''
      >
        {(isTablet ? _3_x_3 : _2_x_2)
            .map((slide: JSX.Element[], i: number) => (

          <Carousel.Item key={ i } >
              <CustomContainer 
                style={{
                  height: isTablet ? '670px' : '620px',
                }}
              >
                <div 
                  className='w-100 d-flex flex-wrap h-100'
                >
                    { slide.map((el: JSX.Element, i: number) => {
                      // console.log(el)
                      return (
                        <div
                          style={{
                            width: isTablet ? "33%" : "50%",
                            height: isTablet ? "33%" : "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          key={i}
                        >
                          { el }
                        </div>
                      )})
                    }
                </div>
              </CustomContainer>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  )
}
