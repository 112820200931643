import React from 'react';
import { Advice } from '../data/advicesForDebtorData';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '../hooks/useMediaQuery';

export default function AdviceForDebtor({ text, img, title }: Advice) {
  const { t } = useTranslation();
  const isTablet: boolean = useMediaQuery('(min-width: 960px)');

  return (
    <div
      style={{
        maxWidth: isTablet ? "33.333%" : "",
        paddingRight: isTablet ? "30px" : "",
      }}
    >
      <div
        className="d-flex align-items-center my-2 gap-3">
        <div
          style={{
            height: isTablet ? "60px" : "45px",
            width: isTablet ? "60px" : "45px",
            backgroundImage: `url(${img})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "50% 50%",
            backgroundSize: isTablet ? "" : "15px",
          }}
          className="
            custom-primary-background-color
            rounded-circle
            d-inline
            flex-shrink-0"
          ></div>
        <h6 className="custom-primary-color">{ t(title) }</h6>
      </div>

      <p>{ t(text) }</p>
    </div>
  )
}
