import React from 'react';
import styled from './index.module.scss';
import CustomContainer from '../customContainer/CustomContainer';
import Heading from '../heading/Heading';

import akbu from '../../assets/akbu.jpg';
import iso from '../../assets/isoStandart.jpg';
import logoNew from '../../assets/logoBlue.png';
import logoOld from '../../assets/oldLogo.jpg';
import bankPolski from '../../assets/bankPolski.jpg';
import restart from '../../assets/restart.jpg';
import { useTranslation } from 'react-i18next';

interface YearProps {
  year: string; 
  eventsList: {text: string, img?: string}[]
}

function Year({ year, eventsList }: YearProps) {
  const { t } = useTranslation();
  return (
    <div>
      <h3 className={ styled.h3 }>{ year }</h3>
      { eventsList.map((el: {text: string, img?: string}, i: number ) => (
        <div key={i}>
          <p className={ styled.description }> { t(el.text) }</p>
          { el.img && (<img className={ styled.img } src={ el.img } alt={ el.text } />)}
        </div>
      ))}
    </div>
  )
}

const years: number[] = [ 2024, 2017, 2000, 1994 ];

const yearsSegment1: YearProps[] = [
  {
    year: '2023',
    eventsList: [
      {
        text: 'history-sec-1-2023-bila',
      }
    ]
  },
  {
    year: '2021',
    eventsList: [
      {
        text: 'history-sec-1-2021',
      }
    ]
  },
  {
    year: '2019',
    eventsList: [
      {
        text: 'history-sec-1-2019-kremenchuk',
      }
    ]
  },
  {
    year: '2018',
    eventsList: [
      {
        text: 'history-sec-1-2018-akbu',
        img: akbu,
      }
    ]
  },
];

const yearsSegment2: YearProps[] = [
  {
    year: '2017',
    eventsList: [
      {
        text: 'history-sec-1-2017-rebranding',
        img: logoNew,
      },
      {
        text: 'history-sec-1-2017-iso',
        img: iso,
      },
    ]
  },
  {
    year: '2016',
    eventsList: [
      {
        text: 'history-sec-1-2016',
      }
    ]
  },
  {
    year: '2011',
    eventsList: [
      {
        text: 'history-sec-2-2011-bank-polski',
        img: bankPolski,
      }
    ]
  },
  {
    year: '2009',
    eventsList: [
      {
        text: 'history-sec-2-2009-restart',
        img: restart,
      },
    ]
  },
];

const yearsSegment3: YearProps[] = [
  {
    year: '2000',
    eventsList: [
      {
        text: 'history-sec-3-2000',
      }
    ]
  },
  {
    year: '1999',
    eventsList: [
      {
        text: 'history-sec-3-1999',
      },
    ]
  },
  {
    year: '1994',
    eventsList: [
      {
        text: 'history-sec-3-1994',
        img: logoOld,
      },
    ]
  },
];

interface PropsYearsSegment {
  years: number[];
}

function YearsSegment({ years}: PropsYearsSegment ) {
  return (
    <div>
      <div 
        style={{
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        { years.map((year: number) => (
          <span key={ year } className={ styled.year }> { year }</span>
        ))}
      </div>
      <div className={ styled.lines_container }>
        <div
          style={{
            width: "33.333%",
            display: "flex"
          }}
        >
          <span className={ `${styled.line_big} ${styled.w7}`}></span> {/* 2024 */}
          <span className={ `${styled.line_small} ${styled.w7}`}></span> {/* 2023 */}
          <span className={ `${styled.line_small} ${styled.w7}`}></span> {/* 2022 */}
          <span className={ `${styled.line_small} ${styled.w7}`}></span> {/* 2021 */}
          <span className={ `${styled.line_small} ${styled.w7}`}></span> {/* 2020 */}
          <span className={ `${styled.line_small} ${styled.w7}`}></span> {/* 2019 */}
          <span className={ `${styled.line_small} ${styled.w7}`}></span> {/* 2018 */}
        </div>
        <div
          style={{
            width: "33.333%",
            display: "flex"
          }}
        >
          <span className={ `${styled.line_big} ${styled.w17}`}></span> {/* 2017 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2016 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2015 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2014 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2013 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2012 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2011 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2010 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2009 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2008 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2007 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2006 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2005 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2004 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2003 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2002 */}
          <span className={ `${styled.line_small} ${styled.w17}`}></span> {/* 2001 */}
        </div>
        <div
          style={{
            width: "33.333%",
            display: "flex"
          }}
        >
          <span className={ `${styled.line_big} ${styled.w6}`}></span> {/* 2000 */}
          <span className={ `${styled.line_small} ${styled.w6}`}></span>  {/* 1999 */}
          <span className={ `${styled.line_small} ${styled.w6}`}></span>  {/* 1998 */}
          <span className={ `${styled.line_small} ${styled.w6}`}></span>  {/* 1997 */}
          <span className={ `${styled.line_small} ${styled.w6}`}></span>  {/* 1996 */}
          <span className={ `${styled.line_small} ${styled.w6}`}></span>  {/* 1995 */}
        </div>
      </div>
    </div>
  )
}

interface Props {
  id: string;
}

export default function History({ id }: Props) {
  const { t } = useTranslation();
  return (
    <div id={ id }>
      <CustomContainer>
        <Heading mode="h3" heading={ t('history-title') }/>
      </CustomContainer>
      <div className={ styled.years_container }>
        <YearsSegment years={ years }/>
        <div
          style={{
            display: "flex"
          }}
        >
          <div className={ styled.year_container}>
            {yearsSegment1.map(({ year, eventsList }: YearProps) => (
              <Year year={year} eventsList={eventsList} key={year}/>
            ))}
          </div>

          <div className={ styled.year_container}>
            {yearsSegment2.map(({ year, eventsList }: YearProps) => (
              <Year year={year} eventsList={eventsList} key={year}/>
            ))}
          </div>

          <div className={ styled.year_container}>
            {yearsSegment3.map(({ year, eventsList }: YearProps) => (
              <Year year={year} eventsList={eventsList} key={year}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
