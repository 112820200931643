import background from '../assets/corousel1/pexels-photo-97130-min.jpg';
import { FirstBlockData } from '../components/FirstBlock';

export const firstPressCenterData: FirstBlockData = {
  id: "section-0",
  background: background,
  data: {
    title: 'first-pressCenter-title',
    descriptionList: [
      'first-pressCenter-description-1',
      'first-pressCenter-description-2',
      'first-pressCenter-description-3',
      'first-pressCenter-description-4'
    ],
    linkList: [
      {
        to: '#section-1',
        fontClass: '_icon-search',
        name: 'first-pressCenter-link-1',
      },
      {
        to: '#section-2',
        fontClass: '_icon-content',
        name: 'first-pressCenter-link-2',
      },
    ]
  }
}

export default firstPressCenterData;