import React from 'react';
import { SelectServiceDataItem } from '../data/selectServiceData';
import SelectServiceListItem from './SelectServiceListItem';

interface Props {
  list: SelectServiceDataItem[];
}

export default function SelectServiceList({ list }: Props) {
  return (
    <div className="d-flex gap-3 align-items-start flex-wrap pb-5">
      { list.map((item: SelectServiceDataItem) => (
        <SelectServiceListItem 
          key={item.title}
          gif={item.gif}
          img={item.img}
          title={item.title}
          to={item.to}
          text={item.text}
        />
      ))}
    </div>
  )
}
