import background from '../assets/granti-dlya-biznesu-u-diya-792x528.jpg'
import dorovir from "../assets/files/Dorovir-ofertu.docx";

const ourProjectsData = {
  id: "section-0",
  background: background,
  data: {
    title: '"Просвіта українців - наш вклад в перемогу"',
    descriptionList: [
      'Якщо ви шукаєте якісну психологічну підтримку для себе чи своєї команди, то потрапили за адресою. ',
      'Незалежно від того, чи ви менеджер, або людина, яка потребує особистої консультації.',
      'Наші послуги стануть корисним інструментом у вашому житті та кар\'єрному розвитку.',
      'В Україні зараз війна, і безліч людей переживає складний період.',
      ' Саме тому, ми хочемо допомогти вам знайти внутрішню стійкість, яка допоможе пройти через стресові ситуації.',
      'Наша головна мета - створити довіру та забезпечити вас надійними рішеннями.',
    ],
    linkList: [
      {
        to: '#section-1',
        fontClass: '_icon-open-book',
        name: 'Консультації психолога',
      },
      {
        to: '#section-2',
        fontClass: '_icon-open-book',
        name: 'Тренінги',
      },
      {
        to: dorovir,
        file: true,
        fontClass: '_icon-info',
        name: 'Договір оферти',
      },
    ]
  }
}

export default ourProjectsData;