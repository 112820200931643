import React from 'react';
import SectionContainer from './SectionContainer';
import { SelectServiceData } from '../data/selectServiceData';
import SelectServiceList from './SelectServiceList';

interface Props {
  id: string;
  data: SelectServiceData;
}

export default function SelectService({ id, data }: Props) {
  return (
    <SectionContainer 
      id={id} 
      mode={'h3'} 
      positionText={'center'}
      title={data.title}
      description={data.description}
    >
      <SelectServiceList list={ data.list }/>
    </SectionContainer>
  )
}
