import img from '../assets/Ivanna_Potapchuk.jpg';

export interface OurTeamItem {
  img: string,
  name: string,
  position: string,
}

export interface OurTeamData {
  title: string;
  list: OurTeamItem[];
}

const ourTeamData: OurTeamData = {
  title: "our-team",
  list: [
    {
      img: img,
      name: 'Олена Гриневич',
      position: 'Генеральний директор',
    },
    // {
    //   img: img,
    //   name: 'Пьотр Анткевич',
    //   position: 'Заступник Генерального директора',
    // },
    // {
    //   img: img,
    //   name: 'Євген Акутін',
    //   position: 'Директор Департаменту досудового врегулювання',
    // },
    {
      img: img,
      name: 'Вікторія Костенко',
      position: 'Директор Департаменту розвитку',
    },
    {
      img: img,
      name: 'Дмитро Муравйов',
      position: 'Директор Департаменту бізнес-операцій',
    },
    {
      img: img,
      name: 'Юлія Вєннікова',
      position: 'Директор департаменту досудового врегулювання',
    },
    {
      img: img,
      name: 'Буряк Наталія',
      position: 'Керівник проектів',
    },
  ]
}

export default ourTeamData;
