import background from '../assets/corousel1/pexels-photo-97130-min.jpg';
import { FirstBlockData } from '../components/FirstBlock';
import file1 from '../assets/files/poryadok-rozglyadu-zvernen.pdf';
import file2 from '../assets/files/poryadok-zahistu-personalnih-danih.pdf';

export const debtorData: FirstBlockData = {
  id: "section-0",
  background: background,
  data: {
    title: 'first-for-debtor-title',
    descriptionList: [
      'first-for-debtor-description-1',
      'first-for-debtor-description-2',
      'first-for-debtor-description-3',
    ],
    linkList: [
      {
        to: '#section-1',
        fontClass: '_icon-chat',
        name: 'first-for-debtor-link-1',
      },
      {
        to: '#section-2',
        fontClass: '_icon-info',
        name: 'first-for-debtor-link-2',
      },
      {
        to: '#section-3',
        fontClass: '_icon-info',
        name: 'nav-for-debtor-nbu-req',
      },
      {
        to: file1,
        file: true,
        fontClass: '_icon-info',
        name: 'first-for-debtor-link-3',
      },
      {
        to: file2,
        file: true,
        fontClass: '_icon-info',
        name: 'first-for-debtor-link-4',
      },
    ]
  }
}

export default debtorData;
