// nav menu data menu

export interface MenuData {
  name: string;
  to: string;
  list: { name: string, to: string }[];
}

export const menuData: MenuData[] = [
  {
    name: 'nav-about-company',
    to: 'about-company#',
    list: [
      {
        name: 'nav-about-company-about-us',
        to: 'about-company#section-1',
      },
      {
        name: 'nav-about-company-history',
        to: 'about-company#section-2',
      },
      {
        name: 'nav-about-company-ours-command',
        to: 'about-company#section-3',
      },
      {
        name: 'nav-about-company-regional-offices',
        to: 'about-company#section-4',
      },
      {
        name: 'nav-about-company-clients',
        to: 'about-company#section-5',
      },
    ]
  },
  {
    name: 'nav-services',
    to: 'services#',
    list: [
      {
        name: 'nav-services-bebt-collection',
        to: 'services#section-1',
      },
      {
        name: 'nav-services-contact-center',
        to: 'services#section-2',
      },
      {
        name: 'nav-services-business-service',
        to: 'services#section-3',
      },
    ]
  },
  {
    name: 'nav-for-debtor',
    to: 'for-debtor#',
    list: [
      {
        name: 'nav-for-debtor-qa',
        to: 'for-debtor#section-1',
      },
      {
        name: 'nav-for-debtor-advice',
        to: 'for-debtor#section-2',
      },
      {
        name: 'nav-for-debtor-nbu-req',
        to: 'for-debtor#section-3',
      },
    ]
  },
  {
    name: 'nav-press-center',
    to: 'press-center#',
    list: [
      {
        name: 'nav-press-center-news',
        to: 'press-center#section-1',
      },
      {
        name: 'nav-press-center-publications',
        to: 'press-center#section-2',
      },
    ]
  },
  {
    name: 'nav-our-projects',
    to: 'our-projects#',
    list: [],
  },
  {
    name: 'nav-contacts',
    to: '#footer',
    list: [],
  }
]
