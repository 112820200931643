import React from 'react';
import { Route, Routes } from 'react-router-dom';
import About from './pages/about';
import Home from './pages/home';
import BottomNav from './components/BottomNav';
import TopNav from './components/topNav/TopNav';
import Footer from './components/footer/Footer';
import Services from './pages/services';
import ForDebtor from './pages/forDebtor';
import PressCenter from './pages/pressCenter';
import NewsFullScreen from './components/NewsFullScreen';
import OurProjects from './pages/ourProjects';

function App() {
  

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
      }}
    >
      <TopNav/>
      <BottomNav/>

      <Routes>
        <Route
          path=':lang?/'
          // loader={({ params }) => {
          //   console.log(params['lang']); 
          // }}
          // action={({ params }) => {
          //   i18n.changeLanguage(params["lang"])
          // }}
        >
          <Route
            index
            element={<Home />}
          />
          <Route 
            path="about-company"
            element={<About />}
          />
          <Route 
            path="services"
            element={<Services />}
          />
          <Route 
            path="for-debtor"
            element={<ForDebtor />}
          />
          <Route 
            path="press-center"
            element={<PressCenter />}
          >
            {/* <Route path="news/:index" element={<NewsFullScreen />}/> */}
            {/* <Route path="publications/:id" element={<Post />}/> */}
          </Route>
          <Route 
            path="press-center/:type/:index" 
            element={<NewsFullScreen />}
            />
          <Route 
            path="our-projects"
            element={<OurProjects />}
          />
          <Route
            path='*'
            element={<Home />}
          />
        </Route>
      </Routes>

      <Footer/>
    </div>
  );
}

export default App;
