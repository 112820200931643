import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import styled from './index.module.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

interface Props {
  onClick: () => void
}

export default function FormFeedback({ onClick }: Props) {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const currentForm = form.current;
    // this prevents sending emails if there is no form.
    // in case currentForm cannot possibly ever be null,
    // you could alert the user or throw an Error, here
    if (currentForm == null) return;

    emailjs.sendForm(
      'service_v94sv03', //service id
      'template_ilv6rb5', //template id
      currentForm, 
      'PDLKdlnELje0SM6xQ' //public key
      ).then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    onClick()
  };

  return (
    <div 
      className={ styled.container } 
      onClick={ e => e.stopPropagation()}
    >
      <Form ref={form} onSubmit={sendEmail}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className='text-muted'>Ім'я</Form.Label>
          <Form.Control type="text" name="user_name" placeholder="Ім'я" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className='text-muted'>Електронна пошта</Form.Label>
          <Form.Control type="email" name="user_email" placeholder="Електронна пошта" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className='text-muted'>Повідомлення</Form.Label>
          <Form.Control name="user_message" as="textarea" rows={4} />
        </Form.Group>
        <Button variant="primary" type="submit">
          Надіслати
        </Button>
      </Form>
    </div>
  )
}
