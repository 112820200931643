import React from 'react';
import styled from './index.module.scss';
import { useTranslation } from 'react-i18next';

export interface DescriptionListProps {
  list: string[];
  textAling?: 'start' | 'center' | 'end';
}

function DescriptionList({ list, textAling="start" }: DescriptionListProps) {
  const { t } = useTranslation();
  
  return (
    <div className={styled.container}>
      { list.map((item: string, idex: number) => (
        <p 
          key={ idex }
          className={styled.paragraph}
          style={{ textAlign: textAling }}
        >{ t(item) }</p>
      ))}
    </div>
  )
}

export default DescriptionList;
