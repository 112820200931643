import { ServicesSectionData } from "../components/servicesSection/ServicesSection";
import background from '../assets/service-bg1.jpg';

const servicesService2: ServicesSectionData = {
  background: background,
  title: 'services-service-2-title',
  textList: [ 
    'services-service-2-list-left-item-1',
    'services-service-2-list-left-item-2',
    'services-service-2-list-left-item-3',
  ],
  id: 'section-2',
  to: '#section-2',
  buttonText: 'services-service-button-text',
  list: [
    'services-service-2-list-right-item-1',
    'services-service-2-list-right-item-2',
    'services-service-2-list-right-item-3',
    'services-service-2-list-right-item-4',
    'services-service-2-list-right-item-5',
    'services-service-2-list-right-item-6',
    'services-service-2-list-right-item-7',
    'services-service-2-list-right-item-8',
  ],
  imgClass: '_icon-call-support',
}

export default servicesService2;