import React, { useState } from 'react';
import styled from './index.module.scss';
import FormFeedback from '../form/Form';
import ModalSlider, { ModalListItem } from '../modalSlider/ModalSlider';

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
  mode: 'form' | "qa";
  list?: ModalListItem[];
  start?: number;
}

function Modal({ children, style, mode, start = 0, list= [] }: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <div style={style} >
      { isOpen && (
        <div
          onClick={ () => { console.log(isOpen); return setIsOpen(false)}}
          className={ styled.modal }
        >
          { mode === "form" && <FormFeedback onClick={() => setIsOpen(false)} />}
          { mode === "qa" && (
            <ModalSlider 
              start={ start } 
              list={list} 
              type={mode}
            />
          )}
        </div>
      )}
      <div
        style={{ width: '100%', height: '100%'}}
        onClick={ () => setIsOpen(true)}
      >{ children }</div>
    </div>
  )
}

export default Modal
