import React from 'react';
import { Link } from 'react-router-dom';
import styled from './index.module.scss';

interface Props {
  to: string;
  text: string;
  className?: string;
  style?: React.CSSProperties;
  mode?: 'white' | 'blue'
}

export default function LinkButton({ 
  to, 
  text, 
  className, 
  style, 
  mode='white'
}: Props) {
  return (
    <Link 
      to={ to }
      className={ `${ styled.link } ${ className } ${ styled[ mode ] }` }
      style={ style }
    >
      { text }
    </Link>
  )
}
