import React from 'react';
import CustomContainer from '../../components/customContainer/CustomContainer';
import { facebookEducation } from '../../data/socialNetworkLinksData';
import ai1 from '../../assets/ai-traning-1.jpg';
import meeting from '../../assets/8.jpg';
import matches from '../../assets/news/matches_image.jpg';
import consultImg  from '../../assets/pexels-shvets-production-7176036.jpg'
import FirstBlock from '../../components/FirstBlock';
import ourProjectsData from '../../data/ourProjectsData';
import tranings from '../../assets/8.10.21_Content_Dev_1182967367-1920x1080.jpg';
import consult from '../../assets/AdobeStock_176035720_800x450.jpg'

function OurProjects() {
  return (
    <div
      className='background-pages'
      style={{
        flex: "1 0 auto"
      }}
    >
      <FirstBlock data={ ourProjectsData }/>
      <CustomContainer>
        <h3 className='text-md-center custom-primary-color fs-2 my-4'>Наша головна мета створити - довіру та забезпечити вас надійними рішеннями</h3>
        <p className='text-md-center mb-4'>Наш психолог є фахівцем у глибинній психології та бізнес тренером. Він може запропонувати вам:</p>
        
        <div className='row mb-4' id='section-1'> 
          <a 
              href={facebookEducation} 
              target='_blank' 
              rel="noreferrer" 
              className='col-12 col-sm-6 col-lg-7 col-xl-8 mb-4 mb-sm-0'
            >
            <div
              style={{
                height: '100%',
                background: `url(${ tranings })`,
                backgroundPosition: '0 30% ',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: "150px"
              }}
            > </div>
          </a>
          <div className='col-xl-4 col-lg-5 col-sm-6 col-12 align-self-center'> 
            <h3 
              className='custom-primary-color'
            >Тренінги</h3>
              <ul>
                <li>Стратегічні сесії для бізнесу</li>
                <li>Тренінги на тему переговорів</li>
                <li>Захист від маніпуляцій НЛП</li>
                <li>Тренінги емоційної стійкості</li>
                <li>Топ навичок успішного керівника</li>
                <li>Успішний бізнес, в умовах стрімких змін</li>
              </ul>
          </div>
        </div>

        <div className='row mb-4 flex-wrap-reverse' id='section-2'> 
          <div 
            className='col-xl-4 col-lg-5 col-sm-6 col-12 align-self-center'
          >
              <h3 className='custom-primary-color'>Консультації психолога:</h3>
              <ul>
                <li>Допомога при емоційному вигоранні</li>
                <li>Підвищення адаптивності, в умовах стресу</li>
                <li>Робота з фобіями</li>
                <li>Проблемні ситуації в стосунках</li>
                <li>Психологічний розвиток</li>
                <li>Коучінг + терапія</li>
              </ul>
          </div>
          <a 
            href={facebookEducation} 
            target='_blank' 
            rel="noreferrer" 
            className='col-12 col-sm-6 col-lg-7 col-xl-8 mb-4 mb-sm-0'
          >
            <div 
              style={{
                height: '100%',
                background: `url(${ consult })`,
                backgroundPosition: '0 30% ',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: '150px'
              }}
            > </div>
          </a>
        </div>


        <div className='row mb-4'>
          <a
            href={facebookEducation}
            target='_blank'
            rel="noreferrer"
            className='col-sm-6 mb-4 mb-sm-0 col-12'
          >
            <div
              style={{
                height: '100%',
                background: `url(${ consultImg })`,
                backgroundPosition: '0',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                minHeight: '250px'
              }}
            > </div>
          </a>
          <div className='col-sm-6 col-12 align-self-center'>
            <p className='custom-dark-grey-color'>
              <span className='custom-primary-color fw-bolder'>Ви можете отримати доступ до всіх цих послуг онлайн та офф-лайн.</span> <br /><br />Ми гарантуємо повну конфіденційність та індивідуальний підхід до кожного клієнта. Ви можете бути впевнені, що відвідавши наші тренінги та консультації, отримаєте якісну та професійну підтримку в розвитку своєї особистості та бізнесу. <br /><br /><span className='custom-primary-color fw-bolder'>Звертайтеся до нас і станьте сильнішими вже сьогодні !</span>
            </p>
          </div>
        </div>
        <div className='row mb-4 flex-wrap-reverse' id='section-2'>
          <div
            className='col-xl-4 col-lg-5 col-sm-6 col-12 align-self-center'
          >
            <p className='custom-dark-grey-color'>
              <span className='custom-primary-color fw-bolder'>Тренінг про стратегію бізнесу в умовах війни і невідомості</span> <br /><br />У часи кризи і невизначеності, особливо під час війни, бізнесу необхідно швидко адаптуватися і знаходити нові шляхи для виживання і розвитку. Наш тренінг надасть вам знання і інструменти для побудови ефективної стратегії, яка дозволить вам залишатися конкурентоспроможними, мінімізувати ризики і використати можливості навіть у найважчі часи. Ми поділимось з вами найкращими практиками і досвідом успішних підприємств. Даний тренінг вже пройшли керівники різних рівнів, з Провідних Українських Компаній. Долучайтеся до сильних!<br /><br />
            </p>
          </div>
          <a
            href={facebookEducation}
            target='_blank'
            rel="noreferrer"
            className='col-12 col-sm-6 col-lg-7 col-xl-8 mb-4 mb-sm-0'
          >
            <div
              style={{
                height: '100%',
                background: `url(${ meeting })`,
                backgroundPosition: '0 30% ',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: '150px'
              }}
            > </div>
          </a>
        </div>

        <div className='row mb-4'>
          <a
            href={facebookEducation}
            target='_blank'
            rel="noreferrer"
            className='col-sm-6 mb-4 mb-sm-0 col-12'
          >
            <div
              style={{
                height: '100%',
                background: `url(${ matches })`,
                backgroundPosition: '0',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% auto',
                minHeight: '250px'
              }}
            > </div>
          </a>
          <div className='col-sm-6 col-12 align-self-center'>
            <p className='custom-dark-grey-color'>
              <span className='custom-primary-color fw-bolder'>Тренінг про стресостійкість і моральний стан працівників</span> <br /><br />Здоровий моральний стан і стресостійкість працівників є ключовими факторами успіху будь-якої компанії, особливо в умовах високої напруги і невизначеності. Наш тренінг допоможе вам зрозуміти, як підтримувати і підвищувати моральний стан команди, розвивати стресостійкість і створювати позитивну робочу атмосферу. Ви отримаєте цінні поради від нашого корпоративного психолога і експерта з управління персоналом, що допоможе підвищити продуктивність і задоволеність працівників. Незважаючи на видиму "банальність" даної теми, Психологічне Здоров`я Українців потребує підтримки, як ніколи раніше. Створіть додаткову опору для свого колективу разом з нами!<br /><br />
            </p>
          </div>
        </div>

        <div className='row mb-4 flex-wrap-reverse' id='section-2'>
          <div
            className='col-xl-4 col-lg-5 col-sm-6 col-12 align-self-center'
          >
            <p className='custom-dark-grey-color'>
              <span className='custom-primary-color fw-bolder'>Тренінг про ШІ</span> <br /><br />В епоху цифрових технологій штучний інтелект стає важливим інструментом для розвитку бізнесу. Наш тренінг допоможе вам зрозуміти, як впровадження AI може покращити ефективність, знизити витрати і відкрити нові можливості для вашого бізнесу. Ви дізнаєтесь про реальні кейси і отримаєте практичні поради. Ми маємо великий досвід у корпоративному впровадженні AI, яким радо поділимося. Ті компанії, які вже купили цей тренінг, отримали від нас практичні навички і конкретні інструменти. За їх відгуками - це позитивно перевертає їх роботу і життя. Одночасно вражає і надихає. Зробіть таку інвестицію і для свого колективу!<br /><br />
            </p>
          </div>
          <a
            href={facebookEducation}
            target='_blank'
            rel="noreferrer"
            className='col-12 col-sm-6 col-lg-7 col-xl-8 mb-4 mb-sm-0'
          >
            <div
              style={{
                height: '100%',
                background: `url(${ ai1 })`,
                backgroundPosition: '0 30% ',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: '150px'
              }}
            > </div>
          </a>
        </div>
      </CustomContainer>
    </div>
  )
}

export default OurProjects;
