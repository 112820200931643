import React from 'react';
import {useTranslation} from "react-i18next";
import SectionContainer from "../SectionContainer";
import {NBUReqData, NBUReqFile} from "../../data/nbuReqData";
import styled from './index.module.scss';
import file from "../../assets/file-info-alt-svgrepo-com.svg";

interface Props {
  id: string;
  data: NBUReqData;
}
export default function NbuRequirements({ id, data }: Props) {
  const { t } = useTranslation();

  return (
    <SectionContainer
    id={id}
    title={"nav-for-debtor-nbu-req"}
    mode='h3'
    positionText='center'
    >
      <div className={ styled.list }>
        { data.list.map((item: NBUReqFile) => (
          <a
            href={ item.path }
            className={styled.list__item}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={file}
              className={styled.icon}
              alt=""
            />
            { t(item.filename) }
          </a>
        ))}
      </div>
    </SectionContainer>
  )
}
