import React from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import styled from './index.module.scss';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
import { useTranslation } from 'react-i18next';

export interface ModalListItem {
  title?: string;
  text?: string;
  img?: string;
  link?: string;
  question?: string;
  answer?: string;
}

interface Props {
  type: "qa" | "news" | "publications",
  list: ModalListItem[];
  start: number;
}

export default function ModalSlider({
  type,
  list,
  start,
}: Props) {
  const { t } = useTranslation();
  
  return (
    <div onClick={ e => e.stopPropagation()} style={{ margin: "50px 0"}}>
      <Swiper
        loop={true}
        navigation={true}
        modules={[Navigation]}
        className={ styled.slider }
        initialSlide={start}
      >
        { list.map((slide, i) => (
          <SwiperSlide 
            key={i}
            className={ styled.slide }
          > 
            <h3> {slide.question && t(slide.question)} </h3>
            <p> {slide.answer && t(slide.answer)} </p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
