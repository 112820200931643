import React from 'react';
import { Carousel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LinkButton from './linkButton/LinkButton';
import Modal from "./modal/Modal";
import useMediaQuery from "../hooks/useMediaQuery";

interface Props {
  slides: Slide[];
}

interface Slide {
  img: string; 
  header: string; 
  text: string; 
  to: string;
}

export default function Slider({ slides }: Props) {
  const { t } = useTranslation();
  const isTablet = useMediaQuery('(min-width: 960px)');

  return (
    <Carousel>
      { slides.map((slide: Slide) => (
        <Carousel.Item key={ slide.header }>

        <div
          style={{
            background: `url(${slide.img})`,
            backgroundPosition: '50% 50%',
            width: '100%',
            minHeight: '800px',
            backgroundSize: 'cover',
            boxShadow: '0 0 0 999px rgba(1,42,88,.6) inset',
          }}
        ></div>
          <Carousel.Caption style={{ marginBottom: '260px'}}>
            <h3 style={{ marginBottom: '40px'}}> { t( slide.header ) } </h3>
            <p style={{ marginBottom: '50px'}}>
              { t(slide.text) }
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: isTablet ? 'row' : 'column' ,
                gap: '10px',
                justifyContent: 'center'
              }}
            >
              <LinkButton to={ slide.to } text={ t('slider-1-button') }/>
              <Modal mode="form">
                <LinkButton
                  style={{ display: 'block'}}
                  to={'#'}
                  text={ t('footer-get-a-consultation') }
                />
              </Modal>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}
