import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HashLink as Link } from 'react-router-hash-link';
import styled from './index.module.scss';

interface DropDownItemProps {
  name: string; 
  to: string;
}

interface DropdownListProps {
  open: boolean;
  list?: DropDownItemProps[];
}

interface Props {
  name: string;
  to: string
  list?: DropDownItemProps[]
}

function DropDownItem({ to, name }: DropDownItemProps) {
  const { t } = useTranslation();
  return (
    <li className={styled.list_item}>
      <Link 
        to={ to } 
        className={styled.link_dropdown_item}
      >
        { t( name ) }
      </Link>
    </li>
  )
}


function DropdownList({ open, list }: DropdownListProps) {
  const hiddenStyle: string = styled.list + ' ' + styled.hidden;
  const visibleStyle: string = styled.list + ' ' + styled.visible;

  return (
    <ul className={ open ? visibleStyle : hiddenStyle}>
        { list!.map((el: DropDownItemProps) => (
          <DropDownItem to={ el.to } name={ el.name } key={el.name} />
        ))}
    </ul>
  )
}


export default function DropDown({ name, list, to }: Props) {
  const [ open, setOpen ] = useState<boolean>(false);
  
  const { t } = useTranslation();

  return (
    <div 
      onMouseEnter={ () => setOpen(true) } 
      onMouseLeave={ () => setOpen(false)}
    >
      
      <Link 
        to={ to } 
        className={styled.link_dropdown}
      >
        { t(name) }
      </Link>

      { list?.length !== 0 && (
        <DropdownList list={ list } open={ open } />
      )}
    </div>
  );
}